import React from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import dayjs from 'dayjs'
import { axiosPrivate } from '../../../common/axiosPrivate'
import { useDispatch } from 'react-redux'
import { addEvents } from '../../../reducers/eventSlice'
import { Box, DialogActions, Button, Dialog, DialogContent, DialogTitle, ListItemIcon, MenuItem } from '@mui/material'
import { csvFileToArray } from '../../../utils/Csv'
import File from '../../Form/File'
import Information from '../../Form/Information'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import EventFromImport from '../../Event/EventFromImport'

const ImportFFVBCsv = props => {
  const { group } = props
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = React.useState(null)
  const [data, setData] = React.useState([])
  const [activeStep, setActiveStep] = React.useState(0)
  const [timeMinusOne, setTimeMinusOne] = React.useState(false)
  const [homeLocation, setHomeLocation] = React.useState(group.location)
  const descriptionElementRef = React.useRef(null)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const onEditAllHomeEvent = homeEvent => {
    const result = []
    data.forEach(ev => {
      ev.match = true
      if (ev.home && !ev.location) {
        ev.location = homeEvent.location
        ev.home = true
      }

      if (homeEvent.timeMinusOne) {
        ev.date = dayjs(ev.date).subtract(1, 'hour').format('YYYY-MM-DD H:mm')
      }
      result.push(ev)
    })
    setData(result)
  }

  const handleNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1)
  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1)
  const handleReset = () => setActiveStep(0)

  const handleClickOpen = e => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }

  const handleClose = () => {
    handleReset(0)
    setData([])
    setFile(null)
    setOpen(false)
  }

  const handleOnChange = e => {
    setFile(e.target.files[0])
  }
  const fileReader = new FileReader()

  const handleReadFile = e => {
    e.preventDefault()
    try {
      if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result
          const result = csvFileToArray(text)
          setData(result)
        }

        fileReader.readAsText(file)
        handleNext()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSave = () => {
    const result = data.map(obj => ({ ...obj, GroupId: group.id }))
    axiosPrivate
      .post('/event', result)
      .then(response => {
        dispatch(addEvents(response.data))
        handleClose()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleHomeLocation = () => {
    onEditAllHomeEvent({ location: homeLocation, timeMinusOne: timeMinusOne })
    handleNext()
  }

  const onDeleteEvent = event => setData(data.filter(ev => ev.date !== event.date))

  const isNextStepDisabled = () => {
    let res = false
    switch (activeStep) {
      case 0:
        res = data.length === 0
        break
      case 1:
        res = homeLocation === ''
        break
      default:
        break
    }
    return res
  }
  const disabled = isNextStepDisabled()
  const steps = ['Importer', 'Informations', 'Finaliser']

  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <FileOpenIcon fontSize="medium" sx={{ mr: 1 }} />
          Import matchs FFVB
        </ListItemIcon>
      </MenuItem>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        onKeyDown={event => {
          if (event.key === 'Tab') {
            event.stopPropagation()
          }
        }}
        scroll="paper"
        maxWidth="xl"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Créez vos matchs FFVB</DialogTitle>
        <DialogContent dividers>
          <DialogContent ref={descriptionElementRef} sx={{ p: { xs: 2 } }}>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
                {steps.map(label => {
                  const stepProps = {}
                  const labelProps = {}
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
              {activeStep === 0 && <File handleOnChange={handleOnChange} handleReadFile={handleReadFile} />}
              {activeStep === 1 && (
                <Information
                  location={homeLocation}
                  timeMinusOne={timeMinusOne}
                  setLocation={setHomeLocation}
                  setTimeMinusOne={setTimeMinusOne}
                />
              )}
              {activeStep === 2 && (
                <React.Fragment>
                  <Box>
                    {data.map(event => (
                      <EventFromImport event={event} onDeleteEvent={onDeleteEvent} key={event.date} fromImport />
                    ))}
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Box>
            <Button onClick={handleClose}>Annuler</Button>
          </Box>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Retour
          </Button>
          {activeStep === steps.length - 1 && !!data.length ? (
            <Button onClick={handleSave}>Créer</Button>
          ) : (
            <>
              <Button onClick={activeStep === 1 ? handleHomeLocation : handleNext} disabled={disabled}>
                Suivant
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ImportFFVBCsv
