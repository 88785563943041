import React from 'react'
import { useSelector } from 'react-redux'
import { Avatar, Box, Chip, Divider, IconButton, Typography } from '@mui/material'
import { Check, ContentCopy, FmdGood } from '@mui/icons-material'
import EventSideBar from './Sidebar'
import dayjs from 'dayjs'
import { slugify, stringAvatar } from '../../../utils/String'
import UsersDisponibilities from './UsersDisponibilities'
import { Link } from 'react-router-dom'

const Information = props => {
  const {
    event,
    usersByDisponibility,
    seeMore,
    userDisponibility,
    setEditing,
    fromImport,
    handleEdit,
    handleCancel,
    handleDelete,
    setSeeMore,
  } = props
  const { groups } = useSelector(state => state.groups)
  const group = groups.find(grp => grp.id === event.GroupId)
  const [highlightedRole, setHighlightedRole] = React.useState(null)
  const [copied, setCopied] = React.useState(false)

  const handleRoleClick = role => {
    highlightedRole === role ? setHighlightedRole(null) : setHighlightedRole(role)
  }

  return (
    <>
      <EventSideBar
        date={dayjs(event.date)}
        group={group}
        event={event}
        users={usersByDisponibility}
        seeMore={seeMore}
        userDisponibility={userDisponibility}
        setEditing={setEditing}
        fromImport={fromImport}
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        handleDelete={handleDelete}
        setSeeMore={setSeeMore}
      />
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flex: 1, my: 2 }}>
          <Box sx={{ display: 'flex', fontSize: { xs: 16, sm: 20 } }}>
            {group && (
              <Avatar
                src={process.env.REACT_APP_IMAGE_URL + '/group/' + group.id + '-' + slugify(group.name) + '.png'}
                sx={{ width: 30, height: 30, mr: 1, ...stringAvatar(group.name).sx }}
              >
                {stringAvatar(group.name).children}
              </Avatar>
            )}
            {event.name}
          </Box>
          <Typography
            variant="body2"
            color={event.location ? 'text.secondary' : '#ff8f5ee6'}
            sx={{ display: 'flex', alignItems: 'center', mt: 2 }}
          >
            <FmdGood sx={{ mr: 1, fontSize: 30 }} />
            {event.location ? (
              <>
                <Link
                  style={{ color: '#FFF', textDecoration: 'none' }}
                  to={`https://maps.google.com/?q=${event.location}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {event.location || 'Adresse manquante'}
                </Link>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(event.location)
                    setCopied(true)
                    setTimeout(() => setCopied(false), 700)
                  }}
                >
                  {copied ? <Check sx={{ color: '#66bb6a' }} /> : <ContentCopy />}
                </IconButton>
              </>
            ) : (
              <>Adresse manquante</>
            )}
          </Typography>
        </Box>
        {usersByDisponibility && (
          <Box sx={{ flex: 1 }}>
            <UsersDisponibilities
              highlightedRole={highlightedRole}
              handleRoleClick={handleRoleClick}
              usersByDisponibility={usersByDisponibility}
              seeMore={seeMore}
              group={group}
            />
          </Box>
        )}
        <Box>
          {!seeMore && (
            <>
              <Divider sx={{ my: 2 }} />
              {usersByDisponibility?.notOk?.length > 0 && (
                <Chip
                  size="small"
                  sx={{ color: '#ff8f5ee6' }}
                  avatar={
                    <Avatar sx={{ backgroundColor: '#ff8f5ee6' }}>
                      <Typography sx={{ color: '#000', fontSize: 10 }}>
                        {usersByDisponibility?.notOk?.length}
                      </Typography>
                    </Avatar>
                  }
                  label={usersByDisponibility?.notOk?.length > 1 ? 'Absents' : 'Absent'}
                />
              )}
              {usersByDisponibility?.needConfirmation?.length > 0 && (
                <Chip
                  size="small"
                  sx={{ color: '#90caf9' }}
                  avatar={
                    <Avatar sx={{ backgroundColor: '#90caf9' }}>
                      <Typography sx={{ color: '#000', fontSize: 10 }}>
                        {usersByDisponibility?.needConfirmation?.length}
                      </Typography>
                    </Avatar>
                  }
                  label={usersByDisponibility?.needConfirmation?.length > 1 ? 'Incertains' : 'Incertain'}
                />
              )}
              {usersByDisponibility?.noResponse?.length > 0 && (
                <Chip
                  size="small"
                  sx={{ color: '#d6c1abcc' }}
                  avatar={
                    <Avatar sx={{ backgroundColor: '#d6c1abcc' }}>
                      <Typography sx={{ color: '#000', fontSize: 10 }}>
                        {usersByDisponibility?.noResponse?.length}
                      </Typography>
                    </Avatar>
                  }
                  label="Sans réponse"
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Information
