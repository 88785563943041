import React from 'react'
import MatchEvent from './Event'
import { useQuery } from '@tanstack/react-query'
import { axiosPrivate } from '../../../common/axiosPrivate'
import dayjs from 'dayjs'
import { Box, CircularProgress } from '@mui/material'

const MatchList = props => {
  const { groupIds, handleScroll } = props
  const [matchs, setMatchs] = React.useState(null)

  const onClick = event => {
    if (dayjs(event.date).isAfter(dayjs())) {
      handleScroll(event.date.slice(0, 10), 70)
    }
  }

  const { isLoading } = useQuery(
    ['matchs', groupIds],
    () =>
      axiosPrivate({
        method: 'get',
        url: '/match',
        params: {
          groupIds: groupIds,
          from: dayjs().hour(0),
        },
      }),
    {
      refetchInterval: 10000,
      enabled: !!groupIds.length,
      onSuccess: response => {
        setMatchs(response.data)
      },
      onError: err => console.log(err),
    }
  )

  if (isLoading)
    return (
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )

  return (
    <>
      {matchs?.map(vbEvent => (
        <MatchEvent event={vbEvent} key={vbEvent.id} handleOnClick={onClick} />
      ))}
    </>
  )
}

export default MatchList
