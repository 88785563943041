import React from 'react'
import { Box, Typography } from '@mui/material'
import { FmdGood } from '@mui/icons-material'
import EventDate from '../Date'

const Information = props => {
  const { event } = props

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', borderRight: 'solid 1px #FFFFFF1F', pr: 1 }}>
        <EventDate date={event.date} />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' } }}>
        <Box sx={{ flex: 2 }}>
          <Box sx={{ display: 'flex' }}>{event.name}</Box>
          <Typography
            variant="body2"
            color={event.location ? 'text.secondary' : '#ff8f5ee6'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <FmdGood sx={{ mr: 1, fontSize: '18px' }} />
            {event.location || 'Adresse manquante'}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Information
