import React from 'react'
import { Box, Avatar, AvatarGroup, Typography, Chip } from '@mui/material'
import { stringAvatar } from '../../utils/String'
import UserAvatar from '../User/Avatar'

const AvatarList = props => {
  const { users, seeMore, group, highlightedRole = null } = props

  return (
    <>
      {!seeMore ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
          <AvatarGroup
            max={32}
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              '& .MuiAvatar-root': {
                border: 'solid 1px #dedede',
                ml: '-5px',
              },
              '& .MuiAvatar-root:last-child': { ml: '-2px' },
            }}
          >
            {users.map(user => {
              return <UserAvatar highlightedRole={highlightedRole} user={user} key={user?.id} />
            })}
          </AvatarGroup>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
          {users.map(user => {
            const fullname = user?.firstname + ' ' + user?.lastname
            return (
              <Box
                key={user.id}
                sx={{ display: 'flex', mt: 1, opacity: highlightedRole && user.role !== highlightedRole ? 0.3 : 1 }}
              >
                <Avatar
                  src={process.env.REACT_APP_IMAGE_URL + '/user/' + user.id + '-' + user.lastname + '.png'}
                  sx={{
                    ...stringAvatar(fullname).sx,
                    width: 24,
                    height: 24,
                    mr: 1,
                    color: '#FFF',
                    fontSize: 13,
                    border: 'solid 1px #dedede',
                  }}
                >
                  {stringAvatar(fullname).children}
                </Avatar>
                <Box sx={{ fontSize: 12, display: 'flex' }}>
                  <Typography sx={{ fontSize: 12, my: 'auto' }}>{user.firstname + ' ' + user.lastname}</Typography>
                  <Typography sx={{ ml: 1, fontSize: 12, fontWeight: 'bold', fontStyle: 'oblique', my: 'auto' }}>
                    {user.number && user.number}
                  </Typography>
                  {group.ligue === 'FFVB' && user.ffvb && (
                    <Chip size="small" sx={{ ml: 1, mr: 1, fontSize: 10 }} label={user.ffvb} />
                  )}
                  {group.ligue === 'FSGT' && user.fsgt && (
                    <Chip size="small" sx={{ ml: 1, mr: 1, fontSize: 10 }} label={user.fsgt} />
                  )}
                </Box>
              </Box>
            )
          })}
        </Box>
      )}
    </>
  )
}

export default AvatarList
