const slugify = str => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')

  return str
}

const stringAvatar = name => {
  let letters = name.indexOf(' ') >= 0 ? name.split(' ')[0][0] + name.split(' ')[1][0] : name.slice(0, 2)

  return {
    sx: {
      bgcolor: stringToColor(name),
      border: 'none',
    },
    children: letters.toUpperCase(),
  }
}

const stringToColor = string => {
  let hash = 0
  let i
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

const capitalize = string => {
  string = string.toLowerCase()
  const arr = string.split(' ')
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  return arr.join(' ')
}

const toFR = string => {
  if (string === 'monday') return 'Lundi'
  if (string === 'tuesday') return 'Mardi'
  if (string === 'wednesday') return 'Mercredi'
  if (string === 'thursday') return 'Jeudi'
  if (string === 'friday') return 'Vendredi'
  if (string === 'saturday') return 'Samedi'
  if (string === 'sunday') return 'Dimanche'
}

export { slugify, stringAvatar, stringToColor, capitalize, toFR }
