import { capitalize } from './String'

const findMyTeam = rows => {
  const teamNames = []
  let result = ''
  rows.slice(0, 2).forEach(row => {
    const values = row.split(';')
    if (teamNames.includes(values[6])) {
      result = values[6]
    } else {
      teamNames.push(values[6])
    }
    if (teamNames.includes(values[8])) {
      result = values[8]
    } else {
      teamNames.push(values[8])
    }
  })
  return result
}

const csvFileToArray = string => {
  const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')
  const myTeamName = findMyTeam(csvRows)
  let result = []
  csvRows.forEach(row => {
    const values = row.split(';')
    if (values.length > 1 && values[6] !== 'xxxxx' && values[8] !== 'xxxxx') {
      result.push({
        home: myTeamName === values[6],
        name: values[6] !== myTeamName ? capitalize(values[6]) : capitalize(values[8]),
        date: values[3] + ' ' + values[4],
        place: capitalize(values[12]),
        address: '',
        match: true,
      })
    }
  })
  return result
}

export { csvFileToArray }
