import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { IconButton, MenuItem } from '@mui/material'
import { Delete } from '@mui/icons-material'

const DeleteConfirmation = props => {
  const { handleDeleteGroup, title, description, type = 'menu' } = props
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleDelete = () => {
    handleDeleteGroup()
    handleClose(false)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {type === 'menu' ? (
        <MenuItem type="button" onClick={handleClickOpen} sx={{ color: '#ff8f5ee6' }}>
          <Delete sx={{ mr: 1 }} /> Supprimer
        </MenuItem>
      ) : (
        <IconButton aria-label="delete" onClick={handleClickOpen} sx={{ color: '#ff8f5ee6' }}>
          <Delete />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleDelete} autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default DeleteConfirmation
