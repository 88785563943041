import React from 'react'
import { Avatar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { slugify, stringAvatar } from '../../../utils/String'
import { calculateWinner } from '../../../utils/Match'

const ScoreTable = props => {
  const { group, score, activeSet = false, handleSetClick = () => {} } = props

  const finalScore = calculateWinner(score)

  return (
    <TableContainer component={Paper} sx={{ mt: 1 }}>
      <Table size="small" aria-label="a dense table">
        {activeSet !== false && (
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {score.a.map((value, index) => (
                <TableCell
                  align="center"
                  onClick={() => handleSetClick(index)}
                  sx={{ color: activeSet === index ? '#90caf9' : 'inherit' }}
                >
                  set {index + 1}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {['a', 'b'].map(team => {
            return (
              <TableRow key={team} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {team === 'a' ? (
                  <TableCell align="center" sx={{ p: 1, width: 30 }}>
                    <Avatar
                      src={process.env.REACT_APP_IMAGE_URL + '/group/' + group.id + '-' + slugify(group.name) + '.png'}
                      sx={{ width: 20, height: 20, ...stringAvatar(group.name).sx, fontSize: 10 }}
                    >
                      {stringAvatar(group.name).children}
                    </Avatar>
                  </TableCell>
                ) : (
                  <TableCell />
                )}
                {score[team].map((value, index) => {
                  return (
                    <TableCell
                      key={team + index}
                      align="center"
                      sx={{ fontSize: 10, backgroundColor: '#FFFFFF1F', p: 1 }}
                      component="th"
                      scope="row"
                    >
                      {value}
                    </TableCell>
                  )
                })}
                <TableCell align="center" sx={{ fontSize: 10, fontWeight: 'bold', p: 1 }} component="th" scope="row">
                  {finalScore.sets[team]}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ScoreTable
