import React from 'react'
import { Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'

const MonthTitle = props => {
  const { date, size } = props
  const dateFR = dayjs(date).format('MMMM YYYY').toUpperCase()
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-FR">
      <Typography
        component="h2"
        sx={{
          p: size === 'small' ? 1 : 2,
          width: '100%',
          backgroundColor: '#0a1929',
          fontSize: size === 'small' ? 12 : 18,
        }}
      >
        {dateFR}
      </Typography>
    </LocalizationProvider>
  )
}

export default MonthTitle
