import { createSlice, current } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

const getUserFromSession = () => {
  const storage = localStorage.getItem('session')
  if (!storage) return null

  const session = JSON.parse(localStorage.getItem('session'))
  const user = jwtDecode(session?.accessToken)
  return user
}

const initialState = {
  user: getUserFromSession(),
  users: {},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    updateUser: (state, action) => {
      state.user = { ...current(state).user, ...action.payload }
    },
    setUsers: (state, action) => {
      state.users = action.payload
    },
  },
})

export const { setUser, setUsers, updateUser } = userSlice.actions

export default userSlice.reducer
