import React from 'react'
import { Button } from '@mui/material'

const ImportMenu = props => {
  const { handleEdit, handleCancel, handleDelete, handleDisplayEditForm } = props
  const [editing, setEditing] = React.useState(false)

  const onClickEdit = () => {
    setEditing(true)
    handleDisplayEditForm(true)
  }

  const onSaveClick = () => {
    setEditing(false)
    handleEdit()
  }

  const onCancelClick = () => {
    setEditing(false)
    handleCancel()
  }

  return (
    <>
      {editing ? (
        <>
          <Button variant="contained" onClick={onSaveClick}>
            OK
          </Button>
          <Button onClick={() => onCancelClick(false)}>Annuler</Button>
        </>
      ) : (
        <>
          <Button onClick={() => onClickEdit()}>Éditer</Button>
          <Button onClick={() => handleDelete()}>Supprimer</Button>
        </>
      )}
    </>
  )
}

export default ImportMenu
