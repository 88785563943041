import * as React from 'react'
import { useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Box } from '@mui/material'
import EventCardInformation from './Card/Information'
import EventCardEdit from './Card/Editing'

const Event = props => {
  const { event, onDeleteEvent, fromImport = false, users } = props
  const [editing, setEditing] = React.useState(false)
  const [name, setName] = React.useState(event.name)
  const [place, setPlace] = React.useState(event.place)
  const [date, setDate] = React.useState(event.date)
  const [address, setAddress] = React.useState(event.address)
  const [seeMore, setSeeMore] = React.useState(false)
  const [usersByDisponibility, setUsersByDisponibility] = React.useState(null)
  const { user } = useSelector(state => state.user)
  const { groups } = useSelector(state => state.groups)
  const group = groups.find(group => group.id === event.GroupId)
  const userDisponibility = event.Disponibilities?.find(dispo => dispo.UserId === user.id)

  React.useEffect(() => {
    let usersByDisp = {
      ok: [],
      notOk: [],
      needConfirmation: [],
      noResponse: group.Users,
    }

    let usersWithResponse = []
    event.Disponibilities?.forEach(dispo => {
      switch (dispo.response) {
        case 1:
          usersByDisp.ok.push(users[dispo.UserId])
          usersWithResponse.push(dispo.UserId)
          break
        case 2:
          usersByDisp.notOk.push(users[dispo.UserId])
          usersWithResponse.push(dispo.UserId)
          break
        case 3:
          usersByDisp.needConfirmation.push(users[dispo.UserId])
          usersWithResponse.push(dispo.UserId)
          break
        default:
          break
      }
    })

    usersByDisp.noResponse = group.Users.filter(user => !usersWithResponse.includes(user.id))
    setUsersByDisponibility(usersByDisp)
  }, [event, users, group])

  const getColorByResponse = response => {
    switch (response) {
      case 1:
        return '#66bb6a'
      case 2:
        return '#ff8f5ee6'
      case 3:
        return '#90caf9'
      default:
        return '#1F2833'
    }
  }

  const getBGColorByResponse = response => {
    switch (response) {
      case 1:
      case 2:
        return '#001e3c'
      case 3:
      default:
        return '#131d28'
    }
  }

  const getBorderColor = () => {
    return fromImport ? (isEventComplete ? '#388e3c' : '#ff8f5ee6') : getColorByResponse(userDisponibility?.response)
  }

  const initialValue = event
  const isEventComplete = event.date && event.place && event.address && event.name

  const handleEdit = () => {
    event.name = name
    event.address = address
    event.place = place
    event.date = date
    setEditing(false)
  }

  const handleDelete = () => onDeleteEvent(event)

  const handleCancel = () => {
    event.name = initialValue.name
    event.address = initialValue.address
    event.place = initialValue.place
    event.date = initialValue.date
    setName(initialValue.name)
    setAddress(initialValue.address)
    setPlace(initialValue.place)
    setDate(initialValue.date)
    setEditing(false)
  }

  return (
    <Card
      key={event.id}
      sx={{
        m: 1,
        backgroundColor: getBGColorByResponse(userDisponibility?.response),
        borderLeft: `solid 8px ${getBorderColor(userDisponibility?.response)}`,
      }}
    >
      <CardContent sx={{ p: { xs: 1, md: 2 } }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {!editing ? (
            usersByDisponibility && (
              <EventCardInformation
                event={event}
                usersByDisponibility={usersByDisponibility}
                seeMore={seeMore}
                userDisponibility={userDisponibility}
                setEditing={setEditing}
                fromImport={fromImport}
                handleEdit={handleEdit}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
                setSeeMore={setSeeMore}
              />
            )
          ) : (
            <EventCardEdit
              name={name}
              setName={setName}
              date={date}
              setDate={setDate}
              place={place}
              setPlace={setPlace}
              address={address}
              setAddress={setAddress}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default Event
