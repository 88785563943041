const calculateWinner = score => {
  if (score) {
    let teamASets = 0
    let teamBSets = 0

    for (let set = 0; set < score.a.length; set++) {
      if (set < 4) {
        if (score.a[set] >= 25 && score.a[set] >= score.b[set] + 2) {
          teamASets++
        } else if (score.b[set] >= 25 && score.b[set] >= score.a[set] + 2) {
          teamBSets++
        }
      } else {
        if (score.a[set] >= 15 && score.a[set] >= score.b[set] + 2) {
          teamASets++
        } else if (score.b[set] >= 15 && score.b[set] >= score.a[set] + 2) {
          teamBSets++
        }
      }
    }

    return { finished: teamASets === 3 || teamBSets === 3, sets: { a: teamASets, b: teamBSets } }
  }
}

const setEnds = (scoreA, scoreB, set) => {
  const limit = set === 4 ? 15 : 25
  if (scoreA >= limit || scoreB >= limit) return Math.abs(scoreA - scoreB) >= 2
  return false
}

export { calculateWinner, setEnds }
