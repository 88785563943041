import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, FormControlLabel, FormGroup, Portal, Switch } from '@mui/material'
import TextField from '@mui/material/TextField'
import { axiosPrivate } from '../../../common/axiosPrivate'
import MenuItem from '@mui/material/MenuItem'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { DateTimePicker } from '@mui/x-date-pickers'
import { addEvents, updateEvent } from '../../../reducers/eventSlice'
import dayjs from 'dayjs'
import ScoreboardIcon from '@mui/icons-material/Scoreboard'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import HomeIcon from '@mui/icons-material/Home'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import AddressAutocomplete from '../../Form/AddressAutocomplete'

const CreateSingleEvent = props => {
  const { event = null, group, title, icon } = props
  const [open, setOpen] = React.useState(false)
  const [where, setWhere] = React.useState(event ? (event.home ? 'home' : 'away') : 'home')
  const [match, setMatch] = React.useState(event ? (event.match ? 'match' : 'training') : 'match')
  const [resetDisponibilities, setResetDisponibilities] = React.useState(false)
  const [dateChanged, setDateChanged] = React.useState(false)
  const dispatch = useDispatch()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleChangeDate = newDate => {
    formik.values.date = newDate.format('YYYY-MM-DD H:mm')
    if (event) setDateChanged(true)
  }

  const handleChangeMatch = (event, newMatch) => {
    if (newMatch) {
      setMatch(newMatch)
      formik.values.match = newMatch === 'match'
    }
  }

  const handleChangeWhere = (event, newWhere) => {
    if (newWhere) {
      setWhere(newWhere)
      formik.values.home = newWhere === 'home'
    }
  }

  const handleClose = () => {
    formik.values.date = null
    setOpen(false)
  }

  const validationSchema = yup.object({
    name: yup.string().max(60).required('Champ obligatoire'),
    date: yup.string().required('Ajoutez une date'),
  })

  const handleChangeSwitch = event => {
    setResetDisponibilities(event.target.checked)
    formik.values.resetDisponibilities = event.target.checked
  }

  const formik = useFormik({
    initialValues: {
      id: event?.id,
      GroupId: group?.id,
      name: event ? event.name : '',
      match: event ? event.match : true,
      date: event ? dayjs(event.date) : null,
      location: event ? event.location : group.location,
      home: event ? event.home : true,
      resetDisponibilities: false,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      event
        ? axiosPrivate({
            method: 'put',
            url: '/event',
            data: values,
          }).then(response => {
            dispatch(updateEvent(response.data))
            handleClose()
          })
        : axiosPrivate
            .post('/event', [values])
            .then(response => {
              dispatch(addEvents(response.data))
              handleClose()
            })
            .catch(error => {
              console.log(error)
            })
    },
  })

  return (
    <React.Fragment>
      <MenuItem onClick={() => setOpen(true)}>
        {icon} {title}
      </MenuItem>
      <Portal>
        <Dialog open={open} onClose={handleClose} onSubmit={formik.handleSubmit} fullScreen={fullScreen}>
          <Box component="form">
            <DialogTitle>{event ? 'Éditer votre séance' : 'Créer une nouvelle séance'}</DialogTitle>
            <DialogContent>
              <ToggleButtonGroup
                color="primary"
                value={match}
                exclusive
                onChange={handleChangeMatch}
                aria-label="Platform"
              >
                <ToggleButton value="match">
                  <ScoreboardIcon sx={{ mr: 1 }} />
                  C'est un match
                </ToggleButton>
                <ToggleButton value="training">
                  <FitnessCenterIcon sx={{ mr: 1 }} />
                  C'est un entrainement
                </ToggleButton>
              </ToggleButtonGroup>
              <TextField
                margin="normal"
                fullWidth
                id="event-name"
                label="Nom de la séance"
                name="name"
                autoComplete="name"
                autoFocus
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    label="Date de la séance"
                    format="DD/MM/YYYY HH:mm"
                    margin="normal"
                    id="date"
                    name="date"
                    autoComplete="date"
                    ampm={false}
                    autoFocus
                    disablePast
                    value={formik.values.date}
                    onChange={newValue => handleChangeDate(newValue)}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                    sx={{ border: formik.errors.date ? 'solid 1px red' : 'none' }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {event && dateChanged && (
                <FormGroup sx={{ mt: 1 }}>
                  <FormControlLabel
                    control={<Switch checked={resetDisponibilities} onChange={handleChangeSwitch} name="gilad" />}
                    label="Réinitialiser les disponibilités pour cet événement ?"
                  />
                </FormGroup>
              )}
              <ToggleButtonGroup
                color="primary"
                value={where}
                exclusive
                onChange={handleChangeWhere}
                aria-label="Platform"
                sx={{ mt: 2, mb: 2 }}
              >
                <ToggleButton value="home">
                  <HomeIcon sx={{ mr: 1 }} />À domicile
                </ToggleButton>
                <ToggleButton value="away">
                  <DirectionsCarIcon sx={{ mr: 1 }} />À l'extérieur
                </ToggleButton>
              </ToggleButtonGroup>
              <AddressAutocomplete
                onSelectLocation={location => (formik.values.location = location)}
                defaultValue={formik.values.location}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button type="submit" variant="contained">
                {event ? 'Éditer' : 'Créer'}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Portal>
    </React.Fragment>
  )
}

export default CreateSingleEvent
