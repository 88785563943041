import React from 'react'
import { TextField, Box, Button } from '@mui/material'
import ReactPlayer from 'react-player'

const File = props => {
  const { handleOnChange, handleReadFile } = props
  const [showVideo, setShowVideo] = React.useState(false)

  return (
    <React.Fragment>
      Allez sur le site FFVB, sur le lien de compétition de votre équipe. Télécharger le fichier CSV, puis importez le
      ici.
      {!showVideo ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
          <Button size="small" variant="outlined" onClick={() => setShowVideo(true)}>
            Où trouver ce fichier ?
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
          <ReactPlayer url="https://youtu.be/QepdWYyALeQ" />
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <TextField
          sx={{ mt: 2, flex: 4 }}
          InputLabelProps={{ shrink: true, accept: 'application/csv' }}
          id="outlined-password-input"
          label="Fichier csv FFVB"
          type="file"
          fullWidth
          onChange={handleOnChange}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: { xs: 1, md: 1 } }}>
          <Button size="large" variant="contained" onClick={handleReadFile}>
            importer
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default File
