import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, FormControl, FormControlLabel, FormGroup, InputLabel, Portal, Select, Switch } from '@mui/material'
import TextField from '@mui/material/TextField'
import { axiosPrivate } from '../../../common/axiosPrivate'
import MenuItem from '@mui/material/MenuItem'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { TimePicker } from '@mui/x-date-pickers'
import AddressAutocomplete from '../../Form/AddressAutocomplete'

const CreateTraining = props => {
  const { group, icon, title } = props
  const [open, setOpen] = React.useState(false)
  const [hour, setHour] = React.useState()
  const [creationHour, setCreationHour] = React.useState()
  const [isActive, setIsActive] = React.useState(true)
  const [creationDay, setCreationDay] = React.useState('')
  const [location, setLocation] = React.useState(group.location)
  const [dayOfWeek, setDayOfWeek] = React.useState('')
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleChangeDayOfWeek = event => {
    setDayOfWeek(event.target.value)
    formik.values.dayOfWeek = event.target.value
  }

  const handleChangeCreationDay = event => {
    setCreationDay(event.target.value)
    formik.values.creationDay = event.target.value
  }

  const handleChangeHour = hour => {
    setHour(hour)
    formik.values.hour = hour.format('HH:mm')
  }

  const handleChangeCreationHour = hour => {
    setCreationHour(hour)
    formik.values.creationHour = hour.format('HH:mm')
  }

  const handleSetLocation = loc => {
    setLocation(loc)
    formik.values.location = loc
  }

  const handleChangeIsActive = event => {
    setIsActive(event.target.checked)
    formik.values.isActive = event.target.checked
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const validationSchema = yup.object({
    name: yup.string().max(60).required('Champ obligatoire'),
  })

  const formik = useFormik({
    initialValues: {
      GroupId: group.id,
      name: '',
      location: group.location,
      isActive: isActive,
      dayOfWeek: dayOfWeek,
      creationDay: creationDay,
      creationHour: creationHour,
      hour: hour,
      home: true,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      axiosPrivate
        .post('/training', values)
        .then(response => {
          handleClose()
        })
        .catch(error => {
          console.log(error)
        })
    },
  })

  return (
    <React.Fragment>
      <MenuItem onClick={() => handleClickOpen(true)}>
        {icon} {title}
      </MenuItem>
      <Portal>
        <Dialog open={open} onClose={handleClose} onSubmit={formik.handleSubmit} fullScreen={fullScreen}>
          <Box component="form">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <TextField
                sx={{ mb: 2 }}
                margin="normal"
                fullWidth
                id="event-name"
                label="Nom de la séance"
                name="name"
                autoComplete="name"
                autoFocus
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <AddressAutocomplete defaultValue={location} onSelectLocation={handleSetLocation} />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="day-of-week">Jour de l'entrainement</InputLabel>
                <Select
                  labelId="day-of-week"
                  value={dayOfWeek}
                  label="Jour de l'entrainement"
                  onChange={handleChangeDayOfWeek}
                >
                  <MenuItem value="monday">Lundi</MenuItem>
                  <MenuItem value="tuesday">Mardi</MenuItem>
                  <MenuItem value="wednesday">Mercredi</MenuItem>
                  <MenuItem value="thursday">Jeudi</MenuItem>
                  <MenuItem value="friday">Vendredi</MenuItem>
                  <MenuItem value="saturday">Samedi</MenuItem>
                  <MenuItem value="sunday">Dimanche</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <TimePicker
                  fullWidth
                  label="Heure de la séance"
                  onChange={handleChangeHour}
                  ampm={false}
                  sx={{ mt: 2, width: '100%' }}
                />
              </LocalizationProvider>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="creation-day">Créer l'entrainement le</InputLabel>
                <Select
                  labelId="creation-day"
                  value={creationDay}
                  label="Créer l'entrainement le"
                  onChange={handleChangeCreationDay}
                >
                  <MenuItem value="monday">Lundi</MenuItem>
                  <MenuItem value="tuesday">Mardi</MenuItem>
                  <MenuItem value="wednesday">Mercredi</MenuItem>
                  <MenuItem value="thursday">Jeudi</MenuItem>
                  <MenuItem value="friday">Vendredi</MenuItem>
                  <MenuItem value="saturday">Samedi</MenuItem>
                  <MenuItem value="sunday">Dimanche</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <TimePicker
                  fullWidth
                  label="Heure de la création"
                  onChange={handleChangeCreationHour}
                  ampm={false}
                  sx={{ mt: 2, width: '100%' }}
                />
              </LocalizationProvider>
              <FormGroup>
                <FormControlLabel
                  control={<Switch onChange={handleChangeIsActive} name="Activer cet entrainement" />}
                  label="Activer cet entrainement"
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button type="submit" variant="contained">
                Créer
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Portal>
    </React.Fragment>
  )
}

export default CreateTraining
