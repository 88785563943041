import * as React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Copyright from '../Copyright/Copyright'
import { axiosPublic } from '../../common/axiosPublic'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import jwtDecode from 'jwt-decode'
import { setUser } from '../../reducers/userSlice'

const validationSchema = yup.object({
  email: yup.string('Entrez votre email').email('Email non valide').required('Email requis'),
  firstname: yup.string().max(40).required('Champ obligatoire'),
  lastname: yup.string().max(40).required('Champ obligatoire'),
  password: yup.string('Entrez votre mot de passe').min(8, 'Minimum 8 caractères').required('Champ obligatoire'),
})

const Signup = () => {
  const { user } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [error, setError] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      axiosPublic
        .post('/auth/register', values)
        .then(response => {
          localStorage.setItem('session', JSON.stringify(response.data))
          const user = jwtDecode(response.data.accessToken)
          dispatch(setUser(user))
        })
        .catch(error => {
          setError(error.response.data.error)
        })
    },
  })

  return user ? (
    <Navigate to="/" />
  ) : (
    <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(login-background.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              S'enregistrer
            </Typography>
            {error && (
              <Alert severity="error" onClose={() => setError(null)}>
                {error}
              </Alert>
            )}
            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="firstname"
                label="Prénom"
                name="firstname"
                autoComplete="given-name"
                autoFocus
                value={formik.values.firstname}
                onChange={formik.handleChange}
                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                helperText={formik.touched.firstname && formik.errors.firstname}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="lastname"
                label="Nom"
                name="lastname"
                autoComplete="family-name"
                autoFocus
                value={formik.values.lastname}
                onChange={formik.handleChange}
                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                helperText={formik.touched.lastname && formik.errors.lastname}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                S'enregistrer
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    S'identifier
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/restore-password" variant="body2">
                    Mot de passe oublié
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Signup
