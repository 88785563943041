import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { axiosPrivate } from '../../common/axiosPrivate'
import { useQuery } from '@tanstack/react-query'
import Event from './Event'
import { setEvents } from '../../reducers/eventSlice'
import MonthTitle from './MonthTitle'
import { Box, Card, CardContent, CircularProgress } from '@mui/material'
import CreateGroup from '../Group/CreateGroupDialog'

const EventList = props => {
  const { setRefs, users, groupIds } = props
  const { groups } = useSelector(state => state.groups)
  const { filter } = useSelector(state => state.filter)
  const { events } = useSelector(state => state.events)
  const dispatch = useDispatch()

  const { isLoading } = useQuery(
    ['events', [groupIds, filter]],
    () =>
      axiosPrivate({
        method: 'get',
        url: '/event',
        params: {
          groupIds: groupIds,
          from: dayjs().hour(0),
          filter,
        },
      }),
    {
      enabled: !!groupIds.length,
      refetchInterval: 30000,
      onSuccess: response => {
        dispatch(setEvents(response.data))
      },
      onError: err => console.log(err),
    }
  )

  const refsById = React.useMemo(() => {
    const refs = {}
    Object.entries(events).forEach(items => {
      refs[items[0]] = React.createRef(null)
      items[1].forEach(item => {
        refs[item.date.slice(0, 10)] = React.createRef(null)
      })
    })
    return refs
  }, [events])

  React.useEffect(() => {
    setRefs(refsById)
  }, [refsById])

  if (!groups.length)
    return (
      <Card
        sx={{
          m: 1,
          backgroundColor: '#001e3c',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 1,
          }}
        >
          <Box sx={{ textAlign: 'center', m: 2 }}>
            Vous n'avez pas encore de groupe ? vous pouvez utiliser la barre de recherche en haut de page pour en
            trouver un.
          </Box>
          <Box sx={{ textAlign: 'center', m: 2 }}>Vous pouvez également choisir d'en créer un</Box>
          <CreateGroup />
        </CardContent>
      </Card>
    )

  if (isLoading)
    return (
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  if (!Object.keys(events).length)
    return (
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>Vous n'avez aucune séance pour le moment.</Box>
    )

  return (
    <>
      {Object.entries(events).map(([yearMonth, eventsByMonth]) => {
        return (
          <React.Fragment key={yearMonth}>
            <Box ref={refsById[yearMonth]}>
              <MonthTitle date={yearMonth} />
            </Box>
            {eventsByMonth.map(vbEvent => (
              <Box ref={refsById[vbEvent.date.slice(0, 10)]} key={vbEvent.id}>
                <Event event={vbEvent} key={vbEvent.id} users={users} />
              </Box>
            ))}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default EventList
