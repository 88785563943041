import React from 'react'
import { Typography, Box, FormControlLabel, Checkbox } from '@mui/material'
import AddressAutocomplete from './AddressAutocomplete'

const Information = props => {
  const { location, setLocation, timeMinusOne, setTimeMinusOne } = props
  return (
    <React.Fragment>
      <Typography sx={{ mt: 2 }}>Confirmer l'adresse de vos matchs à domicile</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }}>
        <AddressAutocomplete onSelectLocation={location => setLocation(location)} defaultValue={location} />

        <Typography sx={{ mt: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={timeMinusOne} onChange={() => setTimeMinusOne(!timeMinusOne)} name="time" />}
            label="Je souhaite avancer l'horaire des matchs afin d'avoir l'heure de rendez-vous."
          />
        </Typography>
      </Box>
    </React.Fragment>
  )
}

export default Information
