import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Portal, MenuItem, FormControl, InputLabel, Select, Avatar, Typography, Badge } from '@mui/material'
import TextField from '@mui/material/TextField'
import AvatarUpload from '../Form/Avatar'
import { axiosPrivate } from '../../common/axiosPrivate'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { updateUser } from '../../reducers/userSlice'
import FaceIcon from '@mui/icons-material/Face'
import { stringAvatar } from '../../utils/String'
import { styled } from '@mui/material/styles'
import { Edit, EditOffOutlined } from '@mui/icons-material'

const validationSchema = yup.object({
  email: yup.string('Entrez votre email').email('Email non valide').required('Email requis'),
  firstname: yup.string().max(40).required('Champ obligatoire'),
  lastname: yup.string().max(40).required('Champ obligatoire'),
  fsgt: yup.number('Utiliez des chiffres uniquement'),
  ffvb: yup.number('Utiliez des chiffres uniquement'),
  number: yup.number('Utiliez des chiffres uniquement'),
})

const CreateGroup = props => {
  const { user } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [avatar, setAvatar] = React.useState('')
  const [displayUploader, setDisplayUploader] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 50,
    height: 50,
    border: `2px solid #DEDEDE`,
  }))

  const resetFields = () => {
    setAvatar('')
  }

  const handleClickOpen = () => {
    resetFields()
    setOpen(true)
  }

  const handleClose = () => {
    resetFields()
    setOpen(false)
  }
  const formik = useFormik({
    initialValues: {
      id: user.id,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      role: user.role || 'coach',
      number: user.number || '',
      ffvb: user.ffvb || '',
      fsgt: user.fsgt || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (avatar) values = { ...values, avatar: avatar }

      axiosPrivate
        .put('/user', values)
        .then(response => {
          dispatch(updateUser(response.data.user))
          // setOpen(false)
        })
        .catch(error => {
          console.log(error)
        })
    },
  })

  const handleImage = image => {
    setAvatar(image)
  }

  const handleSubmit = e => {
    e.preventDefault()
    try {
      formik.handleSubmit()
    } catch (error) {
      console.log(error)
    }
  }

  const fullname = user?.firstname + ' ' + user?.lastname

  return (
    <React.Fragment>
      <MenuItem onClick={handleClickOpen}>
        <FaceIcon sx={{ mr: 1 }} />
        Profil
      </MenuItem>
      <Portal>
        <Dialog open={open} onClose={handleClose} onSubmit={handleSubmit} fullScreen={fullScreen}>
          <Box component="form">
            <DialogTitle>Éditez votre profil</DialogTitle>
            <DialogContent>
              {displayUploader ? (
                <>
                  <AvatarUpload onSetImage={handleImage} />
                  <Button onClick={() => setDisplayUploader(false)}>Annuler</Button>
                </>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <SmallAvatar onClick={() => setDisplayUploader(true)}>
                        <Edit />
                      </SmallAvatar>
                    }
                  >
                    <Avatar
                      src={`${process.env.REACT_APP_IMAGE_URL}/user/${user?.id}-${user?.lastname}.png`}
                      {...stringAvatar(fullname)}
                      sx={{
                        width: 150,
                        height: 150,
                        fontSize: 60,
                        color: '#FFF',
                        ...stringAvatar(fullname).sx,
                        border: 'solid 1px #dedede',
                      }}
                    >
                      {stringAvatar(fullname).children}
                    </Avatar>
                  </Badge>
                </Box>
              )}
              <TextField
                margin="normal"
                disabled
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="firstname"
                label="Prénom"
                name="firstname"
                autoComplete="given-name"
                autoFocus
                value={formik.values.firstname}
                onChange={formik.handleChange}
                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                helperText={formik.touched.firstname && formik.errors.firstname}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="lastname"
                label="Nom"
                name="lastname"
                autoComplete="lastname"
                autoFocus
                value={formik.values.lastname}
                onChange={formik.handleChange}
                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                helperText={formik.touched.lastname && formik.errors.lastname}
              />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FormControl sx={{ flex: 1, mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">Votre poste</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Poste"
                    name="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                  >
                    <MenuItem value="middleBlocker">Central</MenuItem>
                    <MenuItem value="coach">Coach</MenuItem>
                    <MenuItem value="Libero">Libéro</MenuItem>
                    <MenuItem value="setter">Passeur</MenuItem>
                    <MenuItem value="opposite">Pointu</MenuItem>
                    <MenuItem value="outsideHitter">Receptionneur Attaquant</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  margin="normal"
                  sx={{ flex: 1 }}
                  id="number"
                  label="Numéro"
                  name="number"
                  autoComplete="number"
                  autoFocus
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  error={formik.touched.number && Boolean(formik.errors.number)}
                  helperText={formik.touched.number && formik.errors.number}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  sx={{ flex: 1 }}
                  margin="normal"
                  id="ffvb"
                  label="Licence FFVB"
                  name="ffvb"
                  autoComplete="ffvb"
                  autoFocus
                  value={formik.values.ffvb}
                  onChange={formik.handleChange}
                  error={formik.touched.ffvb && Boolean(formik.errors.ffvb)}
                  helperText={formik.touched.ffvb && formik.errors.ffvb}
                />
                <TextField
                  sx={{ flex: 1 }}
                  margin="normal"
                  id="fsgt"
                  label="Licence FSGT"
                  name="fsgt"
                  autoComplete="fsgt"
                  autoFocus
                  value={formik.values.fsgt}
                  onChange={formik.handleChange}
                  error={formik.touched.fsgt && Boolean(formik.errors.fsgt)}
                  helperText={formik.touched.fsgt && formik.errors.fsgt}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button type="submit" variant="contained">
                Sauvegarder
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Portal>
    </React.Fragment>
  )
}

export default CreateGroup
