import { Typography } from '@mui/material'
import React from 'react'
import dayjs from 'dayjs'

const formatDate = (date, format) => {
  return dayjs(date).format(format)
}
const EventDate = props => {
  const { date } = props
  return (
    <>
      <Typography sx={{ textTransform: 'uppercase', textAlign: 'center', fontSize: '20px' }}>
        {formatDate(date, 'ddd').slice(0, 3)}
      </Typography>
      <Typography sx={{ textAlign: 'center', fontSize: '38px', mt: -2 }}>{formatDate(date, 'DD')}</Typography>
      <Typography sx={{ textAlign: 'center', fontSize: '15px', mt: -2, mb: 1, textTransform: 'uppercase' }}>
        {formatDate(date, 'MMM').split(0, 4)}
      </Typography>
      <Typography sx={{ textAlign: 'center', fontSize: '16px', mt: -2, mb: 1 }}>{formatDate(date, 'HH:mm')}</Typography>
    </>
  )
}

export default EventDate
