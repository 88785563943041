import React from 'react'
import { stringAvatar } from '../../../utils/String'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Avatar,
  Box,
  FormControl,
  Portal,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { axiosPrivate } from '../../../common/axiosPrivate'
import MenuItem from '@mui/material/MenuItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation'
import GroupIcon from '@mui/icons-material/Group'

const UserRoleSelect = props => {
  const { user } = props
  const [isEditing, setIsEditing] = React.useState(false)
  const [value, setValue] = React.useState(user.role)

  const handleChangeValue = e => {
    let data = { ...user }
    data.role = e.target.value
    axiosPrivate
      .put('/user', data)
      .then(() => {
        setValue(e.target.value)
        setIsEditing(false)
        // setOpen(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleOnBlur = () => {
    setIsEditing(false)
  }

  const frName = value => {
    switch (value) {
      case 'middleBlocker':
        return 'Central'
      case 'coach':
        return 'Coach'
      case 'libero':
        return 'Libéro'
      case 'setter':
        return 'Passeur'
      case 'opposite':
        return 'Pointu'
      case 'outsideHitter':
        return 'R4'
      default:
        return ''
    }
  }

  return isEditing ? (
    <TableCell align="center">
      <FormControl sx={{ flex: 1, mt: 2, width: 200 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Poste"
          name="role"
          value={value}
          onChange={handleChangeValue}
          onBlur={handleOnBlur}
        >
          <MenuItem value="middleBlocker" selected>
            Central
          </MenuItem>
          <MenuItem value="coach">Coach</MenuItem>
          <MenuItem value="libero">Libéro</MenuItem>
          <MenuItem value="setter">Passeur</MenuItem>
          <MenuItem value="opposite">Pointu</MenuItem>
          <MenuItem value="outsideHitter">R4</MenuItem>
        </Select>
      </FormControl>
    </TableCell>
  ) : (
    <TableCell align="center" onClick={() => setIsEditing(true)}>
      {frName(value)}
    </TableCell>
  )
}

const UserNumber = props => {
  const { user } = props
  const [isEditing, setIsEditing] = React.useState(false)
  const [value, setValue] = React.useState(user.number)

  const handleChangeValue = e => {
    setValue(e.target.value)
  }

  const handleSubmit = e => {
    let data = { ...user }
    data.number = e.target.value

    axiosPrivate
      .put('/user', data)
      .then(() => {
        setIsEditing(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSubmit(e)
    }
  }

  return isEditing ? (
    <TableCell align="center">
      <TextField
        margin="normal"
        sx={{ flex: 1 }}
        id="number"
        label="Numéro"
        name="number"
        autoComplete="number"
        autoFocus
        value={value}
        onChange={handleChangeValue}
        onBlur={handleSubmit}
        onKeyDown={handleKeyDown}
      />
    </TableCell>
  ) : (
    <TableCell align="center" onClick={() => setIsEditing(true)}>
      {value}
    </TableCell>
  )
}

const UserFFVB = props => {
  const { user } = props
  const [isEditing, setIsEditing] = React.useState(false)
  const [value, setValue] = React.useState(user.ffvb)

  const handleChangeValue = e => {
    setValue(e.target.value)
  }

  const handleSubmit = e => {
    let data = { ...user }
    data.ffvb = e.target.value

    axiosPrivate
      .put('/user', data)
      .then(() => {
        setIsEditing(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSubmit(e)
    }
  }

  return isEditing ? (
    <TableCell align="center">
      <TextField
        margin="normal"
        sx={{ flex: 1 }}
        id="ffvb"
        label="FFVB"
        name="ffvb"
        autoComplete="ffvb"
        autoFocus
        value={value}
        onChange={handleChangeValue}
        onBlur={handleSubmit}
        onKeyDown={handleKeyDown}
      />
    </TableCell>
  ) : (
    <TableCell align="center" onClick={() => setIsEditing(true)}>
      {value}
    </TableCell>
  )
}

const UserFSGT = props => {
  const { user } = props
  const [isEditing, setIsEditing] = React.useState(false)
  const [value, setValue] = React.useState(user.fsgt)

  const handleChangeValue = e => {
    setValue(e.target.value)
  }

  const handleSubmit = e => {
    let data = { ...user }
    data.fsgt = e.target.value

    axiosPrivate
      .put('/user', data)
      .then(() => {
        setIsEditing(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSubmit(e)
    }
  }

  return isEditing ? (
    <TableCell align="center">
      <TextField
        margin="normal"
        sx={{ flex: 1 }}
        id="fsgt"
        label="FSGT"
        name="fsgt"
        autoComplete="fsgt"
        autoFocus
        value={value}
        onChange={handleChangeValue}
        onBlur={handleSubmit}
        onKeyDown={handleKeyDown}
      />
    </TableCell>
  ) : (
    <TableCell align="center" onClick={() => setIsEditing(true)}>
      {value}
    </TableCell>
  )
}

const MembersAction = props => {
  const { user, onDeleteUser } = props
  const [isAdmin, setIsAdmin] = React.useState(user.UserGroup.admin)

  const handleChangeIsAdmin = e => {
    let data = JSON.parse(JSON.stringify(user.UserGroup))
    data.admin = e.target.checked
    axiosPrivate.put('/user-group', data).catch(error => {
      console.log(error)
    })
    setIsAdmin(e.target.checked)
  }

  const handleDelete = () => {
    let data = JSON.parse(JSON.stringify(user.UserGroup))
    axiosPrivate
      .delete('/user-group', { data })
      .then(() => {
        onDeleteUser(user.id)
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <TableCell align="center">
        <Switch checked={isAdmin} onChange={handleChangeIsAdmin} inputProps={{ 'aria-label': 'controlled' }} />
      </TableCell>
      <TableCell align="center">
        <DeleteConfirmation
          type="icon"
          handleDeleteGroup={handleDelete}
          title="Retirer ce membre"
          description="Voulez vous vraiment retirer ce membre ?"
        />
      </TableCell>
    </>
  )
}

const ViewMembers = props => {
  const { group } = props
  const [members, setMembers] = React.useState(group.Users)
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleDelete = id => {
    setMembers(members.filter(member => member.id !== id))
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <MenuItem onClick={() => handleClickOpen(true)}>
        <GroupIcon sx={{ mr: 1 }} /> Liste des membres
      </MenuItem>
      <Portal>
        <Dialog open={open} onClose={handleClose} onSubmit={() => {}} fullScreen={true}>
          <Box component="form">
            <DialogTitle>Memdres du groupe {group.name}</DialogTitle>
            <DialogContent>
              <TableContainer>
                <Table aria-label="users table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="center">Poste</TableCell>
                      {group.ligue === 'FFVB' && <TableCell align="center">FFVB</TableCell>}
                      {group.ligue === 'FSGT' && <TableCell align="center">FSGT</TableCell>}
                      <TableCell align="center">Admin</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {members.map(row => (
                      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: 'flex' }}>
                            <Avatar
                              src={process.env.REACT_APP_IMAGE_URL + '/user/' + row.id + '-' + row.lastname + '.png'}
                              sx={{
                                width: 24,
                                height: 24,
                                mr: 1,
                                ...stringAvatar(row.firstname + ' ' + row.lastname).sx,
                                color: '#FFF',
                                fontSize: 14,
                                border: 'solid 1px #ffffff1f',
                              }}
                            >
                              {stringAvatar(row.firstname + ' ' + row.lastname).children}
                            </Avatar>
                            {row.firstname} {row.lastname}
                          </Box>
                        </TableCell>
                        <UserNumber user={row} />
                        <UserRoleSelect user={row} />
                        {group.ligue === 'FFVB' && <UserFFVB user={row} />}
                        {group.ligue === 'FSGT' && <UserFSGT user={row} />}
                        <MembersAction user={row} onDeleteUser={handleDelete} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Portal>
    </React.Fragment>
  )
}

export default ViewMembers
