import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { axiosPrivate } from '../../common/axiosPrivate'
import EventList from '../Event/EventList'
import Calendar from '../Calendar/Calendar'
import Group from '../Group/Group'
import AppHeader from '../AppHeader/AppHeader'
import { setGroups } from '../../reducers/groupSlice'
import CreateGroupDialog from '../Group/CreateGroupDialog'
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball'
import MatchList from '../Event/Match/List'
import ScoreboardIcon from '@mui/icons-material/Scoreboard'
import Filters from '../Group/Filters'

const Home = () => {
  const { groups } = useSelector(state => state.groups)
  const { user } = useSelector(state => state.user)
  const [leftDrawerOpen, setLeftDrawerOpen] = React.useState(false)
  const [rightDrawerOpen, setRightDrawerOpen] = React.useState(false)
  const [eventListRefs, setEventListRefs] = React.useState(null)
  const [groupIds, setGroupIds] = React.useState([])
  const [users, setUsers] = React.useState({})

  React.useEffect(() => {
    setGroupIds(groups.map(group => group.id))
  }, [groups])

  const dispatch = useDispatch()

  const drawerWidth = 324

  const handleLeftDrawerToggle = () => setLeftDrawerOpen(!leftDrawerOpen)
  const handleRightDrawerToggle = () => setRightDrawerOpen(!rightDrawerOpen)

  const handleSetRefs = refs => setEventListRefs(refs)

  const handleScroll = (date, offset) => {
    if (eventListRefs[date]) {
      const elementRect = eventListRefs[date].current.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.scrollY
      const offsetTop = absoluteElementTop - offset
      window.scrollTo({ top: offsetTop, behavior: 'smooth' })
    }
  }

  const onSelectGroup = (isSelected, groupId) => {
    const tmp = [...groupIds]
    if (isSelected) {
      tmp.push(groupId)
      setGroupIds(tmp)
    } else {
      const index = tmp.indexOf(groupId)
      tmp.splice(index, 1)
      setGroupIds(tmp)
    }
  }

  // const urlBase64ToUint8Array = base64String => {
  //   const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  //   const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  //   const rawData = window.atob(base64)
  //   const outputArray = new Uint8Array(rawData.length)

  //   for (let i = 0; i < rawData.length; ++i) {
  //     outputArray[i] = rawData.charCodeAt(i)
  //   }
  //   return outputArray
  // }

  // const sendSubscriptionToServer = subscription => {
  //   axiosPrivate.post('/subscribe', subscription).catch(error => {
  //     console.log(error)
  //   })
  // }

  // React.useEffect(() => {
  //   if ('serviceWorker' in navigator && 'PushManager' in window) {
  //     navigator.serviceWorker
  //       .register('/service-worker.js?v=8')
  //       .then(registration => {
  //         return registration.pushManager.getSubscription().then(subscription => {
  //           if (subscription === null) {
  //             return registration.pushManager.subscribe({
  //               userVisibleOnly: true,
  //               applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY),
  //             })
  //           } else {
  //             return subscription
  //           }
  //         })
  //       })
  //       .then(subscription => {
  //         // Send the subscription to your server
  //         sendSubscriptionToServer(subscription)
  //       })
  //       .catch(error => {
  //         console.error('Error:', error)
  //       })
  //   }
  // }, [])

  const { isLoading } = useQuery(['groups', user, groups], () => axiosPrivate.get('/user'), {
    enabled: !!user,
    onSuccess: response => {
      let users = {}
      dispatch(setGroups(response.data.Groups))

      response.data.Groups.forEach(group => {
        group['Users'].forEach(user => {
          users[user.id] = user
        })
      })
      setUsers(users)
    },
    onError: err => console.log(err),
  })

  if (!user) return <Navigate to="/login" />
  if (isLoading) return <>Loading</>

  const drawer = (
    <Box sx={{ backgroundColor: '#0a1929' }}>
      <Toolbar>
        <SportsVolleyballIcon sx={{ mr: 2 }} /> VOLLEY-BALL.EU
      </Toolbar>
      <Divider />
      <Calendar handleScroll={date => handleScroll(date, 70)} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Filters />
        <Group handleSelect={onSelectGroup} />
        <CreateGroupDialog />
      </Box>
    </Box>
  )

  const RightDrawer = (
    <Box sx={{ backgroundColor: '#0a1929' }}>
      <Toolbar>
        <ScoreboardIcon sx={{ mr: 2 }}></ScoreboardIcon> Résultats
      </Toolbar>
      <Divider />
      <MatchList groupIds={groupIds} handleScroll={handleScroll} />
    </Box>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth * 2}px)` },
          mx: { md: `${drawerWidth}px` },
        }}
      >
        <AppHeader handleLeftDrawerToggle={handleLeftDrawerToggle} handleRightDrawerToggle={handleRightDrawerToggle} />
      </AppBar>
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={leftDrawerOpen}
          onClose={handleLeftDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#0b1929',
              backgroundImage: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#0b1929' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <Toolbar />
        <EventList setRefs={handleSetRefs} users={users} handleScroll={handleScroll} groupIds={groupIds} />
      </Box>

      <Box sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
        <Drawer
          variant="temporary"
          anchor="right"
          open={rightDrawerOpen}
          onClose={handleRightDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#0b1929',
              backgroundImage: 'none',
            },
          }}
        >
          {RightDrawer}
        </Drawer>
        <Drawer
          variant="permanent"
          anchor="right"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#0b1929' },
          }}
          open
        >
          {RightDrawer}
        </Drawer>
      </Box>
    </Box>
  )
}
export default Home
