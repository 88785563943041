import React from 'react'
import { Avatar, Box, Chip, Divider, Typography } from '@mui/material'
import { Cancel, CheckCircle, Help } from '@mui/icons-material'
import AvatarList from '../../Avatar/AvatarList'
import RoleChips from '../../RoleChips/RoleChips'

const UsersDisponibilities = props => {
  const { usersByDisponibility, seeMore, group, highlightedRole, handleRoleClick } = props

  return (
    <Box sx={{ display: 'flex', mt: 1, flexDirection: 'column' }}>
      {!!usersByDisponibility.ok.length && (
        <>
          <Box sx={{ flex: 1 }}>
            <Divider>
              <Chip
                size="small"
                sx={{ color: '#66bb6a' }}
                avatar={
                  <Avatar sx={{ backgroundColor: '#66bb6a' }}>
                    <Typography sx={{ color: '#000', fontSize: 10 }}>{usersByDisponibility.ok.length}</Typography>
                  </Avatar>
                }
                label={usersByDisponibility.ok.length > 1 ? 'Présents' : 'Présent'}
              />
            </Divider>
            <AvatarList
              highlightedRole={highlightedRole}
              seeMore={seeMore}
              users={usersByDisponibility.ok}
              color="#66bb6a"
              title={usersByDisponibility.ok.length > 1 ? 'Présents' : 'Présent'}
              icon={<CheckCircle color="success" />}
              group={group}
            />
          </Box>
          <Box>
            <RoleChips
              highlightedRole={highlightedRole}
              handleRoleClick={handleRoleClick}
              usersByDisponibility={usersByDisponibility}
            />
          </Box>
        </>
      )}
      {seeMore && !!usersByDisponibility.notOk.length && (
        <Box sx={{ flex: 1, mt: 1 }}>
          <Divider>
            <Chip
              size="small"
              sx={{ color: '#ff8f5ee6' }}
              avatar={
                <Avatar sx={{ backgroundColor: '#ff8f5ee6' }}>
                  <Typography sx={{ color: '#000', fontSize: 10 }}>{usersByDisponibility.notOk.length}</Typography>
                </Avatar>
              }
              label={usersByDisponibility.notOk > 1 ? 'Absents' : 'Absent'}
            />
          </Divider>
          <AvatarList
            users={usersByDisponibility.notOk}
            color="#ff8f5ee6"
            title={usersByDisponibility.notOk.length > 1 ? 'Absents' : 'Absent'}
            icon={<Cancel color="#ff8f5ee6" />}
            group={group}
          />
        </Box>
      )}
      {seeMore && !!usersByDisponibility.needConfirmation.length && (
        <Box sx={{ flex: 1, mt: 1 }}>
          <Divider>
            <Chip
              size="small"
              sx={{ color: '#90caf9' }}
              avatar={
                <Avatar sx={{ backgroundColor: '#90caf9' }}>
                  {' '}
                  <Typography sx={{ color: '#000', fontSize: 10 }}>
                    {usersByDisponibility.needConfirmation.length}
                  </Typography>
                </Avatar>
              }
              label={usersByDisponibility.needConfirmation > 1 ? 'Incertains' : 'Incertain'}
            />
          </Divider>
          <AvatarList
            users={usersByDisponibility.needConfirmation}
            color="#90caf9"
            title={usersByDisponibility.needConfirmation.length > 1 ? 'Incertains' : 'Incertain'}
            icon={<Help color="#90caf9" />}
            group={group}
          />
        </Box>
      )}
      {seeMore && !!usersByDisponibility.noResponse.length && (
        <Box sx={{ flex: 1, mt: 1 }}>
          <Divider>
            <Chip
              size="small"
              sx={{ color: '#d6c1abcc' }}
              avatar={
                <Avatar sx={{ backgroundColor: '#d6c1abcc' }}>
                  {' '}
                  <Typography sx={{ color: '#000', fontSize: 10 }}>{usersByDisponibility.noResponse.length}</Typography>
                </Avatar>
              }
              label="Sans réponse"
            />
          </Divider>
          <AvatarList
            users={usersByDisponibility.noResponse}
            color="#d6c1abcc"
            title="Sans réponse"
            group={group}
            icon={<Help color="#d6c1abcc" />}
          />
        </Box>
      )}
    </Box>
  )
}

export default UsersDisponibilities
