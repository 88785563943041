import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '../../reducers/filterSlice'

const Filters = () => {
  const { filter } = useSelector(state => state.filter)
  const dispatch = useDispatch()

  const handleChange = e => {
    dispatch(setFilter(e.target.value))
  }

  return (
    <Box>
      <Divider>
        <Chip size="small" label="Filtres" />
      </Divider>
      <Box sx={{ ml: 2 }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="all"
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel value="all" control={<Radio />} label="Tout" />
            <FormControlLabel value="match" control={<Radio />} label="Matchs uniquement" />
            <FormControlLabel value="training" control={<Radio />} label="Entrainements uniquement" />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  )
}

export default Filters
