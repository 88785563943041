import { Avatar, Box, Card, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { slugify, stringAvatar } from '../../../utils/String'
import { useSelector } from 'react-redux'
import ScoreTable from './ScoreTable'
import ScoreBoard from './ScoreBoard'

const Event = props => {
  const { event, handleOnClick } = props
  const { groups } = useSelector(state => state.groups)
  const group = groups.find(grp => grp.id === event.GroupId)
  const date = dayjs(event.date).format('dddd DD MMMM')

  return (
    <Card
      sx={{ m: 1, backgroundColor: '#001e3c', fontSize: 14, p: 1, cursor: 'pointer' }}
      onClick={() => handleOnClick(event)}
    >
      <Box sx={{ display: 'flex' }}>
        {groups.length > 1 && (
          <Avatar
            src={process.env.REACT_APP_IMAGE_URL + '/group/' + group.id + '-' + slugify(group.name) + '.png'}
            sx={{ width: 20, height: 20, mr: 1, ...stringAvatar(group.name).sx, fontSize: 10 }}
          >
            {stringAvatar(group.name).children}
          </Avatar>
        )}
        <Box sx={{ flex: 1 }}>{event.name}</Box>
        <ScoreBoard event={event} group={group} />
      </Box>
      {event.score?.a ? (
        <ScoreTable group={group} score={event.score} />
      ) : (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ mt: 1, fontSize: 12, color: 'grey', flex: 1 }}>{date}</Typography>
        </Box>
      )}
    </Card>
  )
}

export default Event
