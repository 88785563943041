import React from 'react'
import { useDispatch } from 'react-redux'
import EventIcon from '@mui/icons-material/Event'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import ImportFFVBCsv from './Menu/ImportFFVB'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CreateSingleEvent from './Menu/CreateSingleEvent'
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation'
import { axiosPrivate } from '../../common/axiosPrivate'
import { deleteGroup } from '../../reducers/groupSlice'
import CreateTraining from './Menu/CreateTraining'
import ViewTrainings from './Menu/Trainings'
import Members from './Menu/Members'

const GroupMenu = props => {
  const { group } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e, reason) => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    axiosPrivate
      .delete('/group', { data: { id: group.id, name: group.name } })
      .then(() => {
        dispatch(deleteGroup(group))
      })
      .catch(err => console.log(err))
  }

  return (
    <Box sx={{ flex: 1 }}>
      <IconButton
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVertIcon fontSize="medium" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        keepMounted
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <CreateSingleEvent group={group} title="Nouvelle Séance" icon={<EventIcon sx={{ mr: 1 }} />} />
        <CreateTraining group={group} title="Nouvel Entrainement" icon={<EventRepeatIcon sx={{ mr: 1 }} />} />
        <ImportFFVBCsv group={group} />
        <Divider />
        <ViewTrainings group={group} />
        <Members group={group} />
        <Divider />
        <DeleteConfirmation
          handleDeleteGroup={handleDelete}
          title="Êtes-vous sûr de vouloir supprimer ce groupe ?"
          description="Supprimer ce groupe supprimera également tous les séances (matchs et entraînements) sans possibilité de
            retour."
        />
      </Menu>
    </Box>
  )
}

export default GroupMenu
