import React from 'react'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Portal, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { axiosPrivate } from '../../../common/axiosPrivate'
import MenuItem from '@mui/material/MenuItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation'
import { toFR } from '../../../utils/String'
import { Edit } from '@mui/icons-material'

const TrainingActions = props => {
  const { training, onDeleteTraining } = props
  const [isActive, setIsActive] = React.useState(true)

  const handleChangeIsActive = e => {
    const data = { ...training, ...{ isActive: e.target.checked } }
    axiosPrivate
      .put('/training', data)
      .then(response => {})
      .catch(error => {
        console.log(error)
      })
    setIsActive(e.target.checked)
  }

  const handleDelete = () => {
    axiosPrivate
      .delete('/training', { data: { id: training.id } })
      .then(() => {
        onDeleteTraining(training.id)
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <Switch checked={isActive} onChange={handleChangeIsActive} inputProps={{ 'aria-label': 'controlled' }} />
      <DeleteConfirmation
        type="icon"
        handleDeleteGroup={handleDelete}
        title="Supprimer cet entrainement"
        description="Voulez vous vraiment supprimer cet entrainement ? Vos séances ne seront plus automatiquement crées."
      />
    </>
  )
}

const ViewTrainings = props => {
  const { group } = props
  const [trainings, setTrainings] = React.useState(group.Trainings)
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleDelete = id => {
    const test = trainings.filter(training => training.id !== id)
    setTrainings(test)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <MenuItem onClick={() => handleClickOpen(true)}>
        <Edit sx={{ mr: 1 }} /> Liste des entrainements
      </MenuItem>
      <Portal>
        <Dialog open={open} onClose={handleClose} onSubmit={() => {}} fullScreen={fullScreen}>
          <Box component="form">
            <DialogTitle>Les entrainements pour {group.name}</DialogTitle>
            <DialogContent>
              <TableContainer>
                <Table aria-label="trainings table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom</TableCell>
                      <TableCell align="center">Jour</TableCell>
                      <TableCell align="center">Heure</TableCell>
                      <TableCell align="center">Créer le</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trainings.map(row => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{toFR(row.dayOfWeek)}</TableCell>
                        <TableCell align="center">{row.hour}</TableCell>
                        <TableCell align="center">
                          {toFR(row.creationDay)} à {row.creationHour}
                        </TableCell>
                        <TableCell align="right">
                          <Box sx={{ display: 'flex' }}>
                            <TrainingActions training={row} onDeleteTraining={handleDelete} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Portal>
    </React.Fragment>
  )
}

export default ViewTrainings
