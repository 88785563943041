import React from 'react'
import { useDispatch } from 'react-redux'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CreateSingleEvent from '../../Group/Menu/CreateSingleEvent'
import DeleteConfirmation from '../../DeleteConfirmation/DeleteConfirmation'
import { axiosPrivate } from '../../../common/axiosPrivate'
import { deleteEvent } from '../../../reducers/eventSlice'
import ScoreBoard from '../Match/ScoreBoard'

const EventMenu = props => {
  const { event, group } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e, reason) => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    axiosPrivate
      .delete('/event', { data: { id: event.id } })
      .then(() => {
        dispatch(deleteEvent(event))
      })
      .catch(err => console.log(err))
  }

  return (
    <Box sx={{ flex: 1 }}>
      <IconButton
        onClick={handleClick}
        aria-controls={open ? 'event-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVertIcon fontSize="medium" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="event-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        keepMounted
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <CreateSingleEvent event={event} group={group} title="Éditer" icon={<EditIcon sx={{ mr: 1 }} />} />
        <Divider />
        <DeleteConfirmation
          handleDeleteGroup={handleDelete}
          title="Êtes-vous sûr de vouloir supprimer cette séance ?"
          description="Supprimer cet événement supprimera également les réponses de tous les membres du groupe."
        />
      </Menu>
    </Box>
  )
}

export default EventMenu
