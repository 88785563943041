import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Box } from '@mui/material'
import ImportMenu from '../Event/ImportMenu'
import Information from './Card/ImportInformation'
import EventCardEdit from './Card/Editing'

const EventFromImport = props => {
  const { event, onDeleteEvent } = props
  const [editing, setEditing] = React.useState(false)
  const [name, setName] = React.useState(event.name)
  const [date, setDate] = React.useState(event.date)
  const [location, setLocation] = React.useState(event.location)

  const initialValue = event

  const handleEdit = () => {
    event.name = name
    event.location = location
    event.date = date
    setEditing(false)
  }

  const handleDelete = () => onDeleteEvent(event)

  const handleCancel = () => {
    event.name = initialValue.name
    event.location = initialValue.location
    event.date = initialValue.date
    setName(initialValue.name)
    setLocation(initialValue.location)
    setDate(initialValue.date)
    setEditing(false)
  }

  return (
    <Card
      key={event.id}
      sx={{
        m: 1,
        backgroundColor: '#001e3c',
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
          {!editing ? (
            <Information event={event} />
          ) : (
            <EventCardEdit
              name={name}
              setName={setName}
              date={date}
              setDate={setDate}
              location={location}
              setLocation={setLocation}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <ImportMenu
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            handleDelete={handleDelete}
            handleDisplayEditForm={() => setEditing(true)}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default EventFromImport
