import { Box, IconButton, Tooltip } from '@mui/material'
import EventDate from '../Date'
import EventMenu from './Menu'
import { ZoomOutMap, ZoomInMap } from '@mui/icons-material'
import Disponibility from '../Disponibility'

const EventSidebar = props => {
  const { date, group = null, event, userDisponibility, seeMore, setSeeMore } = props

  return (
    <Box sx={{ borderRight: 'solid 1px #ffffff1f', pr: 2 }}>
      <EventDate date={date} />
      {group?.UserGroup.admin && <EventMenu event={event} group={group} />}
      <Tooltip title="Plus d'info" placement="left" arrow>
        <IconButton onClick={() => setSeeMore(!seeMore)}>{seeMore ? <ZoomInMap /> : <ZoomOutMap />}</IconButton>
      </Tooltip>
      <Disponibility event={event} userDisponibility={userDisponibility} seeMore={seeMore} />
    </Box>
  )
}
export default EventSidebar
