const combineObjects = (...objects) => {
  const combinedObject = {}

  objects.forEach(obj => {
    for (const key in obj) {
      if (!combinedObject[key]) {
        combinedObject[key] = []
      }
      combinedObject[key] = [...combinedObject[key], ...obj[key]]
    }
  })

  // Trier les éléments de chaque tableau par la propriété de date croissante
  for (const key in combinedObject) {
    combinedObject[key].sort((a, b) => new Date(a.date) - new Date(b.date))
  }

  return combinedObject
}

export { combineObjects }
