import * as React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Avatar from '@mui/material/Avatar'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Copyright from '../Copyright/Copyright'
import { axiosPublic } from '../../common/axiosPublic'
import jwtDecode from 'jwt-decode'
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from '../../reducers/userSlice'
import { Navigate } from 'react-router-dom'

const validationSchema = yup.object({
  email: yup.string('Entrez votre email').email('Email non valide').required('Email requis'),
  password: yup.string('Entrez votre mot de passe').min(8, 'Minimum 8 caractères').required('Mot de passe requis'),
})

const Login = () => {
  const { user } = useSelector(state => state.user)
  const [error, setError] = React.useState(null)

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      axiosPublic
        .post('/auth/login', values)
        .then(response => {
          localStorage.setItem('session', JSON.stringify(response.data))
          const user = jwtDecode(response.data.accessToken)
          dispatch(setUser(user))
        })
        .catch(error => {
          console.log(error)
          setError(error.message)
        })
    },
  })

  return user ? (
    <Navigate to="/" />
  ) : (
    <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(login-background.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Identification
            </Typography>
            {error && (
              <Alert severity="error" onClose={() => setError(null)}>
                {error}
              </Alert>
            )}
            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                S'identifier
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/register" variant="body2">
                    S'enregistrer
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/restore-password" variant="body2">
                    Mot de passe oublié
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Login
