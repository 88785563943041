import React from 'react'
import Avatar from 'react-avatar-edit'

const AvatarUpload = props => {
  const { onSetImage } = props

  const onCrop = data => {
    onSetImage(data)
  }

  const onBeforeFileLoad = elem => {
    if (elem.target.files[0].size > 8000000) {
      alert('Fichier trop volumineux')
      elem.target.value = ''
    }
  }

  return (
    <Avatar
      width="100%"
      height={200}
      onCrop={onCrop}
      onBeforeFileLoad={onBeforeFileLoad}
      label="Télécharger une image"
      labelStyle={{ color: '#FFF' }}
    />
  )
}

export default AvatarUpload
