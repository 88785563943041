import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Portal,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { axiosPrivate } from '../../common/axiosPrivate'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import SearchResultGroup from '../Group/SearchResult'
import SearchResultEvents from '../Event/SearchResult'

const TopBarSearch = () => {
  const [open, setOpen] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [searchResponse, setSearchResponse] = React.useState({})
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }))

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }))

  const handleSearchClick = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
    setSearchResponse({})
  }

  const handleSearchChange = event => {
    setSearch(event.target.value)
  }

  const { isLoading } = useQuery(
    ['search', search],
    () =>
      axiosPrivate({
        method: 'get',
        url: '/search',
        params: {
          search: search,
        },
      }),
    {
      enabled: !!search,
      onSuccess: response => {
        setSearchResponse(response.data)
      },
      onError: err => console.log(err),
    }
  )

  return (
    <>
      <Search onClick={handleSearchClick}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase placeholder="Recherche" inputProps={{ 'aria-label': 'search' }} />
      </Search>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth="xl" fullScreen={fullScreen}>
          <Box component="form">
            <DialogContent>
              <Typography sx={{ textAlign: 'center' }}>
                Vous pouvez ici rechercher n'importe quel groupe et le rejoindre
              </Typography>
              <TextField
                sx={{ minWidth: { xs: null, sm: 600 } }}
                margin="normal"
                fullWidth
                autoFocus
                onChange={handleSearchChange}
                placeholder="Recherche"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box>
                <>
                  {search.length > 2 &&
                    (!!searchResponse.groups?.length ? (
                      <SearchResultGroup groups={searchResponse.groups} />
                    ) : (
                      <>Pas de résultat pour votre recherche</>
                    ))}
                  {/* {!!searchResponse.events?.length && <SearchResultEvents events={searchResponse.events} />} */}
                </>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Portal>
    </>
  )
}

export default TopBarSearch
