import mem from 'mem'

import { axiosPublic } from './axiosPublic'

const refreshTokenFn = async () => {
  try {
    const currentSession = JSON.parse(localStorage.getItem('session'))
    const response = await axiosPublic.post('/auth/refreshToken', {
      data: currentSession,
    })

    if (!response.data?.accessToken) {
      localStorage.removeItem('session')
      localStorage.removeItem('user')
    }

    localStorage.setItem('session', JSON.stringify(response.data))

    return response.data
  } catch (error) {
    localStorage.removeItem('session')
    localStorage.removeItem('user')
  }
}

const maxAge = 100
export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
})
