import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  Typography,
  styled,
  Badge,
} from '@mui/material'
import { PlayArrow } from '@mui/icons-material'
import { slugify, stringAvatar } from '../../../utils/String'
import { axiosPrivate } from '../../../common/axiosPrivate'
import { useDispatch } from 'react-redux'
import { updateEvent } from '../../../reducers/eventSlice'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import ScoreTable from './ScoreTable'
import { calculateWinner, setEnds } from '../../../utils/Match'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

const ScoreBoard = props => {
  const { event, group } = props
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false)
  const [inProgress, setInProgress] = React.useState(event.inProgress)
  const [score, setScore] = React.useState(event.score ? event.score : { a: [0], b: [0] })
  const [activeSet, setActiveSet] = React.useState(0)
  const [sideRight, setSideRight] = React.useState(0)
  const [finished, setFinished] = React.useState(0)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdateScore = ({ inProgress, score }) => {
    const tmp = {
      id: event.id,
      inProgress: inProgress,
      score: score,
    }
    axiosPrivate({
      method: 'put',
      url: '/event',
      data: tmp,
    }).then(response => {
      dispatch(updateEvent(response.data))
      setInProgress(response.data.inProgress)
    })
  }

  const handleMatchFinished = finalScore => {
    setFinished(true)
    handleUpdateScore({ inProgress: true, score: finalScore })
  }

  const handleSwitchSide = () => {
    setSideRight(!sideRight)
  }

  const handleAddPoint = team => {
    const tmp = JSON.parse(JSON.stringify(score))
    tmp[team][activeSet]++
    const currentScore = calculateWinner(tmp)

    if (currentScore.finished) {
      setScore(tmp)
      handleMatchFinished(tmp)
    } else {
      if (setEnds(tmp.a[activeSet], tmp.b[activeSet], activeSet)) {
        if (activeSet + 1 === tmp.a.length) {
          setActiveSet(activeSet + 1)
          tmp.a.push(0)
          tmp.b.push(0)
        }
      }
      setScore(tmp)
      handleUpdateScore({ inProgress: true, score: tmp })
    }
  }

  const handleRemovePoint = team => {
    const tmp = JSON.parse(JSON.stringify(score))
    if (tmp[team][activeSet] > 0) {
      tmp[team][activeSet]--
      setScore(tmp)
      handleUpdateScore({ inProgress: true, score: tmp })
    }
  }

  const onSetClick = set => {
    setActiveSet(set)
  }

  const handleValidateScore = () => {
    handleUpdateScore({ inProgress: false, score: score })
    const tmp = { ...event }
    tmp.inProgress = false
    console.log(tmp)
    dispatch(updateEvent(tmp))
    handleClose()
  }

  return (
    <React.Fragment>
      {inProgress ? (
        <StyledBadge overlap="circular" sx={{ ml: 1 }} variant="dot" onClick={() => setOpen(true)} />
      ) : (
        <PlayArrow onClick={() => setOpen(true)}></PlayArrow>
      )}
      <Portal>
        <Dialog open={open} onClose={handleClose} fullScreen={fullScreen}>
          <Box component="form">
            <DialogTitle>Prise du score</DialogTitle>
            <DialogContent>
              {!inProgress ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <Typography>
                    Voulez vous démarrer la prise du score pour votre match ? L'événement sera noté "live" tant le score
                    ne sera pas complet.
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ mt: 2, width: 200 }}
                    onClick={() => handleUpdateScore({ inProgress: true, score: { a: [0], b: [0] } })}
                  >
                    Démarrer
                  </Button>
                </Box>
              ) : (
                <>
                  <ScoreTable group={group} score={score} activeSet={activeSet} handleSetClick={onSetClick} />
                  {finished ? (
                    <Box sx={{ display: 'flex', my: 2 }}>
                      <Button variant="outlined" sx={{ mr: 2, flex: 1 }} onClick={() => setFinished(false)}>
                        Modifier
                      </Button>
                      <Button variant="contained" sx={{ flex: 1 }} onClick={handleValidateScore}>
                        Valider
                      </Button>
                    </Box>
                  ) : (
                    <>
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={handleSwitchSide} variant="outlined">
                          <SwapHorizIcon />
                        </Button>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: sideRight ? 'row-reverse' : 'row', mt: 1 }}>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', my: 'auto', mx: 2 }}>
                          <Avatar
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              '/group/' +
                              group.id +
                              '-' +
                              slugify(group.name) +
                              '.png'
                            }
                            sx={{ width: 40, height: 40, ...stringAvatar(group.name).sx, fontSize: 20 }}
                          >
                            {stringAvatar(group.name).children}
                          </Avatar>
                        </Box>
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                          <Button
                            size="small"
                            sx={{ color: 'black', mt: 1, fontSize: 20, backgroundColor: '#66bb66', p: 0 }}
                            onClick={() => handleAddPoint('a')}
                          >
                            +
                          </Button>
                          <Button
                            size="small"
                            sx={{ color: 'black', mt: 1, fontSize: 20, backgroundColor: '#ff8f5e', p: 0 }}
                            onClick={() => handleRemovePoint('a')}
                          >
                            -
                          </Button>
                        </Box>
                        <Box
                          sx={{ flex: 1, display: 'flex', justifyContent: 'center', my: 'auto', mx: 1, fontSize: 30 }}
                        >
                          {score.a[activeSet]}
                        </Box>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', my: 'auto' }}>-</Box>
                        <Box
                          sx={{ flex: 1, display: 'flex', justifyContent: 'center', my: 'auto', mx: 1, fontSize: 30 }}
                        >
                          {score.b[activeSet]}
                        </Box>
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                          <Button
                            size="small"
                            sx={{ color: 'black', mt: 1, fontSize: 20, backgroundColor: '#66bb66', p: 0 }}
                            onClick={() => handleAddPoint('b')}
                          >
                            +
                          </Button>
                          <Button
                            size="small"
                            sx={{ color: 'black', mt: 1, fontSize: 20, backgroundColor: '#ff8f5e', p: 0 }}
                            onClick={() => handleRemovePoint('b')}
                          >
                            -
                          </Button>
                        </Box>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', my: 'auto', mx: 2 }}>
                          <Avatar sx={{ width: 40, height: 40, ...stringAvatar(event.name).sx, fontSize: 20 }}>
                            {stringAvatar(event.name).children}
                          </Avatar>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Portal>
    </React.Fragment>
  )
}

export default ScoreBoard
