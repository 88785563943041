import { configureStore } from '@reduxjs/toolkit'
import groupReducer from './reducers/groupSlice'
import userReducer from './reducers/userSlice'
import eventReducer from './reducers/eventSlice'
import filterReducer from './reducers/filterSlice'

export const store = configureStore({
  reducer: {
    groups: groupReducer,
    events: eventReducer,
    user: userReducer,
    filter: filterReducer,
  },
})
