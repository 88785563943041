import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { CheckCircle, Cancel, Help } from '@mui/icons-material'
import { axiosPrivate } from '../../common/axiosPrivate'
import { useDispatch } from 'react-redux'
import { updateEvent } from '../../reducers/eventSlice'
import LoadingButton from '../LoadingButton/LoadingButton'

const Disponibility = props => {
  const { userDisponibility, event } = props
  const dispatch = useDispatch()

  const handleUpdateDisponibility = disponibility => {
    const data = { ...userDisponibility, ...{ response: disponibility } }
    axiosPrivate
      .put('/disponibility', data)
      .then(response => {
        dispatch(updateEvent(response.data.event))
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleCreateDisponibility = disponibility => {
    const data = {
      groupId: event.GroupId,
      eventId: event.id,
      response: disponibility,
    }
    axiosPrivate
      .post('/disponibility', data)
      .then(response => {
        dispatch(updateEvent(response.data.event))
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleDisponibilityClick = disponibility => {
    userDisponibility ? handleUpdateDisponibility(disponibility) : handleCreateDisponibility(disponibility)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {userDisponibility?.response !== 1 && (
        <Tooltip title="Présent" placement="left" arrow>
          <LoadingButton color="success" callback={() => handleDisponibilityClick(1)}>
            <CheckCircle />
          </LoadingButton>
        </Tooltip>
      )}

      {userDisponibility?.response !== 2 && (
        <Tooltip title="Absent" placement="left" arrow>
          <LoadingButton sx={{ color: '#ff8f5ee6' }} callback={() => handleDisponibilityClick(2)}>
            <Cancel />
          </LoadingButton>
        </Tooltip>
      )}

      {userDisponibility?.response !== 3 && (
        <Tooltip title="À confirmer" placement="left" arrow>
          <LoadingButton sx={{ color: '#90caf9' }} callback={() => handleDisponibilityClick(3)}>
            <Help />
          </LoadingButton>
        </Tooltip>
      )}
    </Box>
  )
}

export default Disponibility
