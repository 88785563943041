import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, InputAdornment, Portal, IconButton, ToggleButtonGroup, ToggleButton } from '@mui/material'
import TextField from '@mui/material/TextField'
import AvatarUpload from '../Form/Avatar'
import { axiosPrivate } from '../../common/axiosPrivate'
import QueueIcon from '@mui/icons-material/Queue'
import { addGroup } from '../../reducers/groupSlice'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import AddressAutocomplete from '../Form/AddressAutocomplete'

const CreateGroup = props => {
  const [open, setOpen] = React.useState(false)
  const [avatar, setAvatar] = React.useState('')
  const [name, setName] = React.useState('')
  const [location, setLocation] = React.useState('')
  const dispatch = useDispatch()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [showPassword, setShowPassword] = React.useState(false)

  const [ligue, setLigue] = React.useState('ffvb')

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const resetFields = () => {
    formik.values.name = ''
    formik.values.location = ''
    setAvatar('')
  }

  const handleClickOpen = () => {
    resetFields()
    setOpen(true)
  }

  const handleClose = () => {
    resetFields()
    setOpen(false)
  }

  const handleChangeLigue = (event, newLigue) => {
    setLigue(newLigue)
    formik.values.ligue = newLigue
  }

  const validationSchema = yup.object({
    name: yup.string().max(40).required('Champ obligatoire'),
    password: yup.string().max(240).required('Champ obligatoire'),
    ligue: yup.string().required('Champ obligatoire'),
  })

  const formik = useFormik({
    initialValues: {
      name: name,
      password: '',
      ligue: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      values = { ...values, location: location }
      if (avatar) {
        values = { ...values, avatar: avatar }
      }
      axiosPrivate
        .post('/group', values)
        .then(response => {
          setName('')
          setAvatar('')
          setLocation('')
          dispatch(addGroup(response.data))
          setOpen(false)
        })
        .catch(error => {
          console.log(error)
        })
    },
  })

  const handleImage = image => {
    setAvatar(image)
  }

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" sx={{ m: 2, width: '290px' }} onClick={handleClickOpen}>
          <QueueIcon sx={{ mr: 2 }} />
          Créer un groupe
        </Button>
      </Box>
      <Portal>
        <Dialog open={open} onClose={handleClose} onSubmit={formik.handleSubmit} fullScreen={fullScreen}>
          <Box component="form">
            <DialogTitle>Créer un nouveau groupe</DialogTitle>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                minWidth: { xs: '100%', sm: '500px' },
              }}
            >
              <TextField
                fullWidth
                margin="normal"
                id="group-name"
                label="Nom du groupe"
                name="name"
                autoComplete="name"
                autoFocus
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <AddressAutocomplete onSelectLocation={location => setLocation(location)} />
              <ToggleButtonGroup
                color="primary"
                value={ligue}
                exclusive
                onChange={handleChangeLigue}
                aria-label="Platform"
                sx={{ my: 1, width: '100%' }}
              >
                <ToggleButton value="FFVB" sx={{ flex: 1 }}>
                  FFVB
                </ToggleButton>
                <ToggleButton value="FSGT" sx={{ flex: 1 }}>
                  FSGT
                </ToggleButton>
              </ToggleButtonGroup>
              <TextField
                fullWidth
                sx={{ mb: 1 }}
                name="password"
                label="Mot de passe du groupe"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <AvatarUpload onSetImage={handleImage} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Annuler</Button>
              <Button type="submit" variant="contained">
                Créer
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Portal>
    </Box>
  )
}

export default CreateGroup
