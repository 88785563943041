import * as React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import 'dayjs/locale/fr'

const initialValue = dayjs()

const ServerDay = props => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props
  const isSelected = !outsideCurrentMonth && highlightedDays.indexOf(day.format('DD')) >= 0

  return (
    <Badge key={day.toString()} overlap="circular" badgeContent={isSelected ? '🏐' : undefined}>
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  )
}

const VBCalendar = props => {
  const { handleScroll } = props
  const [highlightedDays, setHighlightedDays] = React.useState([])
  const { events } = useSelector(state => state.events)

  const getHighlitghedDays = date => {
    const result = []
    if (events && events[date?.format('YYYY-MM')]) {
      events[date?.format('YYYY-MM')].forEach(item => result.push(item.date.slice(8, 10)))
    }
    return result
  }

  React.useEffect(() => {
    setHighlightedDays(getHighlitghedDays(dayjs()))
    // eslint-disable-next-line
  }, [events])

  const handleMonthChange = date => {
    handleScroll(date.format('YYYY-MM'))
    setHighlightedDays(getHighlitghedDays(date))
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-FR">
      <DateCalendar
        sx={{ color: '#FFF' }}
        defaultValue={initialValue}
        onMonthChange={handleMonthChange}
        onChange={date => handleScroll(date.format('YYYY-MM-DD'))}
        slots={{
          day: ServerDay,
          sx: { color: '#FFF' },
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
          sx: { color: '#FFF' },
        }}
      />
    </LocalizationProvider>
  )
}

export default VBCalendar
