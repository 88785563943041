import { createSlice, current } from '@reduxjs/toolkit'
import { combineObjects } from '../utils/Object'
const initialState = {
  events: {},
}

export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload
    },
    addEvents: (state, action) => {
      state.events = combineObjects(current(state).events, action.payload)
    },
    deleteEvent: (state, action) => {
      const event = action.payload
      const date = event.date.slice(0, 7)
      const tmpEvents = (state.events[date] = current(state).events[date].filter(ev => ev.id !== event.id))
      if (!tmpEvents.length) {
        delete state.events[date]
      } else {
        state.events[date] = tmpEvents
      }
    },
    updateEvent: (state, action) => {
      const date = action.payload.date.slice(0, 7)
      state.events[date] = current(state).events[date].map(t => {
        return t.id === action.payload.id ? action.payload : t
      })
    },
  },
})

export const { setEvents, addEvents, updateEvent, deleteEvent } = eventSlice.actions

export default eventSlice.reducer
