import React from 'react'
import { CircularProgress, IconButton } from '@mui/material'

const LoadingButton = props => {
  const { color, callback, children, sx } = props

  const [isLoading, setIsLoading] = React.useState(false)

  const handleClick = value => {
    setIsLoading(true)
    callback(value)
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress color={color} size={24} sx={{ ...sx, m: 1 }} />
      ) : (
        <IconButton aria-label="in" color={color} sx={sx} onClick={() => handleClick()}>
          {children}
        </IconButton>
      )}
    </>
  )
}

export default LoadingButton
