import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { slugify, stringAvatar } from '../../utils/String'
import GroupMenu from './Menu'
import { Chip, Divider } from '@mui/material'

const Group = props => {
  const { handleSelect } = props
  const { groups } = useSelector(state => state.groups)

  const handleChange = (e, id) => {
    handleSelect(e.target.checked, id)
  }

  return (
    <>
      <Divider>
        <Chip size="small" label="Groupes" />
      </Divider>
      {groups.map(group => (
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Box>
            <Checkbox
              defaultChecked
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              onChange={e => handleChange(e, group.id)}
            />
          </Box>
          <Box sx={{ display: 'flex', minWidth: 200 }}>
            <Avatar
              sx={{ width: '40px', mr: 1 }}
              src={process.env.REACT_APP_IMAGE_URL + '/group/' + group.id + '-' + slugify(group.name) + '.png'}
              {...stringAvatar(group.name)}
            />
            <Tooltip title={group.name} arrow>
              <Typography
                sx={{
                  flex: 1,
                  mt: 1,
                  maxWidth: '150px',
                  overflow: 'hidden',
                }}
              >
                {group.name}
              </Typography>
            </Tooltip>
          </Box>
          {group.UserGroup.admin && <GroupMenu group={group} />}
        </Box>
      ))}
    </>
  )
}

export default Group
