import { Avatar, Box, Chip } from '@mui/material'
import React from 'react'

const RoleChips = props => {
  const { usersByDisponibility, handleRoleClick, highlightedRole } = props

  const counters = {
    coach: 0,
    middleBlocker: 0,
    outsideHitter: 0,
    opposite: 0,
    setter: 0,
    libero: 0,
  }

  usersByDisponibility.ok.forEach(user => {
    counters[user?.role]++
  })

  return (
    <Box sx={{ flex: 1, mt: 1 }}>
      {!!counters.coach && (
        <Chip
          onClick={() => handleRoleClick('coach')}
          size="small"
          sx={{ mr: 1, mt: 1, border: highlightedRole === 'coach' ? 'solid 1px #90caf9' : 'none' }}
          avatar={<Avatar>{counters.coach}</Avatar>}
          label={counters.coach > 1 ? 'Coach' : 'Coach'}
        />
      )}
      {!!counters.outsideHitter && (
        <Chip
          onClick={() => handleRoleClick('outsideHitter')}
          size="small"
          sx={{ mr: 1, mt: 1, border: highlightedRole === 'outsideHitter' ? 'solid 1px #90caf9' : 'none' }}
          avatar={<Avatar>{counters.outsideHitter}</Avatar>}
          label={counters.outsideHitter > 1 ? 'R4' : 'R4'}
        />
      )}
      {!!counters.middleBlocker && (
        <Chip
          onClick={() => handleRoleClick('middleBlocker')}
          size="small"
          sx={{ mr: 1, mt: 1, border: highlightedRole === 'middleBlocker' ? 'solid 1px #90caf9' : 'none' }}
          avatar={<Avatar>{counters.middleBlocker}</Avatar>}
          label={counters.middleBlocker > 1 ? 'Centraux' : 'Central'}
        />
      )}
      {!!counters.opposite && (
        <Chip
          onClick={() => handleRoleClick('opposite')}
          size="small"
          sx={{ mr: 1, mt: 1, border: highlightedRole === 'opposite' ? 'solid 1px #90caf9' : 'none' }}
          avatar={<Avatar>{counters.opposite}</Avatar>}
          label={counters.opposite > 1 ? 'Pointus' : 'Pointu'}
        />
      )}
      {!!counters.setter && (
        <Chip
          onClick={() => handleRoleClick('setter')}
          size="small"
          sx={{ mr: 1, mt: 1, border: highlightedRole === 'setter' ? 'solid 1px #90caf9' : 'none' }}
          avatar={<Avatar>{counters.setter}</Avatar>}
          label={counters.setter > 1 ? 'Passeurs' : 'Passeur'}
        />
      )}
      {!!counters.libero && (
        <Chip
          onClick={() => handleRoleClick('libero')}
          size="small"
          sx={{ mr: 1, mt: 1, border: highlightedRole === 'libero' ? 'solid 1px #90caf9' : 'none' }}
          avatar={<Avatar>{counters.libero}</Avatar>}
          label={counters.libero > 1 ? 'Liberos' : 'Libero'}
        />
      )}
    </Box>
  )
}

export default RoleChips
