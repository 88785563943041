import React, { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import { Tooltip } from '@mui/material'
import { stringAvatar } from '../../utils/String'

const UserAvatar = props => {
  const { user, highlightedRole } = props
  const fullname = user?.firstname + ' ' + user?.lastname
  const [imageExists, setImageExists] = useState(true)
  useEffect(() => {
    const image = new Image()
    image.src = `${process.env.REACT_APP_IMAGE_URL}/user/${user?.id}-${user?.lastname}.png`
    image.onload = () => {
      setImageExists(true)
    }
    image.onerror = () => {
      setImageExists(false)
    }
  }, [user])

  return imageExists ? (
    <Tooltip disableFocusListener title={fullname} placement="bottom" arrow>
      <Avatar
        sx={{
          width: 30,
          height: 30,
          border: 'solid 1px #dedede',
          color: '#FFF',
          opacity: highlightedRole && user.role !== highlightedRole ? 0.3 : 1,
        }}
        src={`${process.env.REACT_APP_IMAGE_URL}/user/${user?.id}-${user?.lastname}.png`}
      />
    </Tooltip>
  ) : (
    <Tooltip disableFocusListener title={fullname} placement="bottom" arrow>
      <Avatar
        {...stringAvatar(fullname)}
        sx={{
          width: 30,
          height: 30,
          fontSize: 14,
          color: '#FFF',
          opacity: highlightedRole && user.role !== highlightedRole ? 0.3 : 1,
          ...stringAvatar(fullname).sx,
        }}
      >
        {stringAvatar(fullname).children}
      </Avatar>
    </Tooltip>
  )
}

export default UserAvatar
