import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  groups: [],
}

export const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload
    },
    addGroup: (state, action) => {
      const group = action.payload
      state.groups.push(group)
    },
    deleteGroup: (state, action) => {
      const group = action.payload
      state.groups = state.groups.filter(grp => grp.id !== group.id)
    },
    updateGroups: (state, action) => {
      const libs = state.groups.map(lib => {
        const found = action.payload?.find(newGroup => newGroup.id === lib.id)
        if (found) lib = Object.assign(lib, found)
        return lib
      })
      state.groups = libs
    },
  },
})

export const { setGroups, updateGroups, addGroup, deleteGroup } = groupSlice.actions

export default groupSlice.reducer
