import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { slugify, stringAvatar } from '../../utils/String'
import { Button, Divider, IconButton, InputAdornment, TextField } from '@mui/material'
import { axiosPrivate } from '../../common/axiosPrivate'
import { useDispatch, useSelector } from 'react-redux'
import { addGroup } from '../../reducers/groupSlice'
import { Https, Visibility, VisibilityOff, ArrowCircleRight } from '@mui/icons-material'

const JoinGroup = props => {
  const { group } = props
  const { groups } = useSelector(state => state.groups)
  const [displayPassword, setDisplayPassword] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState(false)

  const isJoinable = groupId => {
    return !!groups.find(grp => grp.id === groupId)
  }
  const dispatch = useDispatch()

  const handleClick = group => {
    axiosPrivate({
      method: 'post',
      url: '/user-group',
      data: {
        groupId: group.id,
        password: password,
      },
    })
      .then(() => {
        dispatch(addGroup(group))
      })
      .catch(err => {
        setError(true)
      })
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  const handlePasswordChange = e => {
    setError(false)
    setPassword(e.target.value)
  }

  return (
    <>
      {displayPassword ? (
        <>
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            name="password"
            label="Password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            sx={{ width: '300px', border: error ? 'solid 1px red' : null, m: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            aria-label="fingerprint"
            onClick={() => handleClick(group)}
            disabled={isJoinable(group.id)}
            sx={{ fontSize: '14px' }}
          >
            <ArrowCircleRight />
          </IconButton>
        </>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setDisplayPassword(true)}
          disabled={isJoinable(group.id)}
          startIcon={<Https />}
        >
          Rejoindre
        </Button>
      )}
    </>
  )
}

const SearchResultGroup = props => {
  const { groups: result } = props

  return (
    <React.Fragment>
      <Box sx={{ mt: 1 }}>
        {result.length} {result.length > 1 ? 'groupes trouvés' : 'groupe trouvé'}
      </Box>
      <Divider sx={{ mb: 2 }} />
      {result.map(group => (
        <React.Fragment key={group.id}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Box sx={{ display: 'flex' }}>
              <Avatar
                sx={{ width: '40px', mr: 1 }}
                src={process.env.REACT_APP_IMAGE_URL + '/group/' + group.id + '-' + slugify(group.name) + '.png'}
                {...stringAvatar(group.name)}
              />
              <Tooltip title={group.name} arrow>
                <Typography
                  sx={{
                    m: 1,
                  }}
                >
                  {group.name}
                </Typography>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <JoinGroup group={group} />
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

export default SearchResultGroup
