import React from 'react'
import { Box, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddressAutocomplete from '../../Form/AddressAutocomplete'
import { DateField } from '@mui/x-date-pickers'

const Editing = props => {
  const { name, setName, date, setDate, location, setLocation } = props

  return (
    <Box sx={{ flex: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        size="small"
        label="Nom"
        name="name"
        autoFocus
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
          sx={{ mt: 2, mb: 2, flex: 1 }}
          format="DD/MM/YYYY HH:mm"
          size="small"
          fullWidth
          label="Date"
          defaultValue={dayjs(date)}
          onChange={value => setDate(value)}
        />
      </LocalizationProvider>
      <AddressAutocomplete onSelectLocation={location => setLocation(location)} defaultValue={location} />
    </Box>
  )
}

export default Editing
